import React ,{useEffect,useState}from "react";
import { Video } from "./HomePage";
import banner from '../ressources/images/banner.png'




const About = () => {
  

  return (
    <div className="w-full 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4 bg-white">
      <div className="flex flex-col lg:flex-row justify-between gap-8 shadow-lg rounded-md px-5">
        <div className="w-full flex justify-center lg:w-12/12 items-center flex-col ">
         
          <h1 className="text-3xl lg:text-4xl font-bold  text-[#5A4B40]  mt-[100px]">
          Wir sind „Die HausmännerInnen“ 
          </h1>
          <h1 className="text-xl lg:text-2xl font-bold  text-gray-400 pb-10 mt-[20px]">
          Von Breitenfurtern für Breitenfurter 
          </h1>
          <div className=" font text-xl lg:w-10/12 text-[#5A4B40] text-justify">
            Als Familienbetrieb in Breitenfurt haben wir uns zum Ziel gesetzt,
            eine Vielzahl an Dienstleistungen anzubieten, die in unserer
           
            <div className=" mb-5">
            Gemeinde schwer zu finden sind.
            </div>
             Sie suchen eine Reinigungskraft,
            einen Gärtner oder einfach jemanden, der Ihnen beim Aufbau eines
            Ikea Kastens hilft? Sie möchten nicht jede Woche zum
            Umweltgrundstück fahren und Ihren Müll entsorgen? Sparen Sie sich
            den Stress und die Mühe! 
            <div className="text-center font-bold my-5">
            Lassen Sie uns die Arbeit erledigen!
            </div>
          
       
             Wir
            bieten ein umfangreiches Spektrum an Hausservice-Leistungen, um
            Ihnen den Alltag zu erleichtern. Unsere erfahrenen Mitarbeiter sind
            darauf spezialisiert, höchste Qualität und Sorgfalt in jede Aufgabe
            einzubringen, um Ihnen das bestmögliche Ergebnis zu garantieren.
            Individuell an Ihre Bedürfnisse angepasst, bieten wir
            maßgeschneiderte Lösungen für Ihr Zuhause.<br/> Wir sind flexibel und
            stehen Ihnen zur Verfügung, um Ihren Anforderungen gerecht zu
            werden.<br/><br/> Wir starten mit unserer Arbeit am 15. September, empfehlen
            Ihnen jedoch schon heute Ihren persönlichen Wunschtermin zu sichern.
            Seien Sie unter den Ersten, die von unserem exzellenten Hausservice
            profitieren!<br/> Kontaktieren Sie unser freundliches Team, um weitere
            Informationen zu erhalten und buchen Sie noch heute Ihren Termin. so
            stellen Sie sicher, dass wir uns um Ihr Zuhause kümmern können, sobald
            wir offiziell starten. <br/><br/>
            <div className="text-center font-bold ">
            Wir freuen uns darauf, Ihnen den Weg in ein
            sauberes und  gepflegtes Zuhause entspannt zu ermöglichen.
            </div>
           
          </div>
          <br/>
          <br/>
          <br/>
          <br/>
          <p className=" font text-xl lg:w-10/12 text-[#5A4B40]">
          Rufen Sie uns an:
            <span className=" font-bold text-xl lg:w-10/12 text-[#5A4B40] ml-2">0660 / 6 200 600</span>
          </p>
          <p className=" font text-xl lg:w-10/12 text-[#5A4B40] mb-[80px]  ">
          oder schreiben Sie ein Mail:
          <a href="mailto:service@diehausmaennerinnen.at" className=" font-bold text-xl lg:w-10/12 text-[#5A4B40] ml-2
          border-b border-b-gray-400 hover:text-blue-400 hover:border-b-blue-400">service@diehausmaennerinnen.at</a>
           
          </p>
        </div>
       
      </div>
    </div>
  );
};

export default About;
