import React from "react";

import cleaningImg from "../../../ressources/images/cleaningservicet.png";

import { auth } from "../../../firebase.config";

import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAtom, atom } from "jotai";
import { authUser } from "../../../stores/authAtom";
import {
  getAuth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  User,
  UserCredential,
} from "firebase/auth";
import { FcIphone,FcKey } from "react-icons/fc";
export const anonymUserEmail = atom<string>("");
const SiginwithPhoneNumber = () => {
  const toast = useToast();
  const auth = getAuth();
  const navigate = useNavigate();

  const [userAtom, setUserAtom] = useAtom(authUser);


  let recaptchaVerifier = (window as any).recaptchaVerifier;

  const [phoneNumber, setPhoneNumber] = React.useState("");

  auth.languageCode = "de";
  const [code, setCode] = React.useState("");
  const Confirmcode = () => {
  
   let confirmationResult=  (window as any).confirmationResult
 
    confirmationResult
      .confirm(code)
      .then((result: UserCredential) => {
        // User signed in successfully.
        const user = result.user;
     
        setUserAtom(user)
     navigate("/servicecard")
        // ...
      })
      .catch((error: any) => {
        // User couldn't sign in (bad verification code?)
        // ...
      });
  };

  const SignInHandler = async () => {
    recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
      size: "invisible",
      callback: (response: any) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
      },
    });
    const appVerifier = recaptchaVerifier;
    console.log(recaptchaVerifier);
    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmation) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        (window as any).confirmationResult = confirmation;
        toast({
          title: phoneNumber,
          description: "die Beschtätigungscode wurde per SMS geschickt",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
      
      })
      .catch((error) => {
        toast({
          title: "Fehler",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
      });
  };




  return (
    <>
      <section
        className="bg-[#f7f7f7] min-h-screen flex items-center
justify-center"
      >
        <div
          className="bg-[#f7f8f7] flex rounded-2xl shadow-lg
      max-w-3xl p-5"
        >
          <div className="sm:w-1/2 px-16 ">
            <h2 className="font-bold text-2xl text-[#C0CDB5] mb-10">
              Mit Handy Anmelden
            </h2>

            <label
              htmlFor="phone"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Handy
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
               <FcIphone size={25}/>
              </div>

              <input
              placeholder="+4366*******"
                type="phone"
                id="phone"
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 "
              />
            </div>

            <button
              className="uppercase shadow-lg mt-3 bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300  md:align-middle   bg-[#C0CDB5]
              rounded-md font-medium mb-5 mx-auto py-2 w-[230px] text-white"
              onClick={SignInHandler}
            
            >
              SMS senden
            </button>
            {/* {loading && <CircularProgress color="success" />} */}
            <hr/>
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white mt-7"
            >
              Bästetigungscode
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
              <FcKey size={25}/>
              </div>

              <input
                placeholder="1234567"
                type="text"
                id="email"
                value={code}
                onChange={(e) => {
                  setCode(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 "
              />
            </div>

            <button
              className="uppercase shadow-lg mt-3 bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300  md:align-middle   bg-[#C0CDB5]
              rounded-md font-medium my-3 mx-auto py-2 w-[230px] text-white"
              onClick={Confirmcode}
            >
              Bästetigen
            </button>
            <div
              className="mt-10 grid grid-cols-3 items-center
           text-gray-400"
            ></div>
          </div>
          <div className="w-1/2">
            <img
              className="sm:block hidden rounded-2xl"
              src={cleaningImg}
              alt="#"
            />
          </div>
        </div>
        <div id="recaptcha-container"></div>
      </section>
    </>
  );
};

export default SiginwithPhoneNumber;
