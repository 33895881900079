import React from 'react'
import Lottie from "lottie-react";
import LawnMowing from '../components/ui/components/cards/LawnMowing';
import GardenWatering from '../components/ui/components/cards/GardenWatering';
import GardenOtherServices from '../components/ui/components/cards/GardenOtherServices';
import { Player } from '@lottiefiles/react-lottie-player';
import animation from '../ressources/lotti/underconstruction.json'

const GardeningPages = () => {
  return (
    <div className="w-full  py-[3rem] flex gap-10 flex-col lg:items-center justify-items-stretch px-4 bg-white md:pt-[200px] md:pb-[200px]">
 <LawnMowing/>
 <GardenWatering/>
 <GardenOtherServices/>

    </div>
  )
}

export default GardeningPages