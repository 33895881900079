import React from 'react'
import { FcKey,FcGoodDecision,FcIphone } from "react-icons/fc";
import {useNavigate} from 'react-router-dom'
const TelephoneButton = () => {
  const navigation=useNavigate()
  return (
    
    <button type="button"
    onClick={()=>{
      navigation('/signinwithphone')
    }}
     className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300  font-medium rounded-lg text-md px-5 py-2.5 text-center
       inline-flex items-center dark:focus:ring-[#bfa66f] mr-2 mb-2 w-[280px]">
         <FcIphone size={25} className='mr-5 h-10'/>
         Mit Handy 

  </button>
  )
}

export default TelephoneButton