import React, { useState } from "react";
import { authUser } from "../../stores/authAtom";
import cleaningImg from "../../ressources/images/cleaningservicet.png";
import { TextField } from "@mui/material";
import { auth } from "../../firebase.config";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import { useAtom } from "jotai";
import { Link, useNavigate } from "react-router-dom";

const SignUp = () => {
  const [checked, setChecked] = React.useState(false);
const navigate=useNavigate();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    console.log(checked);
  };

  const [signInWithEmailAndPassword, user, _loading, error] = useSignInWithEmailAndPassword(auth);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userAtom,setUserAtom]=useAtom(authUser);
const logIn=async (e:any)=>{
  e.preventDefault()
  if (email && password) {
    signInWithEmailAndPassword(email, password);
   console.log(user);
   setUserAtom(user?.user);
   //navigate('/dashboard')
 }
}

  return( <>
      <section
        className="bg-[#f7f7f7] min-h-screen flex items-center
justify-center"
      >
        <div
          className="bg-[#f7f8f7] flex rounded-2xl shadow-lg
        max-w-3xl p-5"
        >
          <div className="sm:w-1/2 px-16">
            <h2 className="font-bold text-2xl text-[#2EC4B6]">Anmeldung</h2>

            <p className="text-sm m-4">
              Sie können mit Handynummer auch anmelden
            </p>

            <form onSubmit={logIn}>
              
                <TextField
                  required
                  id="email"
                  label="Email"
                  sx={{ background: "#fff" }}
                  className="w-[200px]"
                  size="small"
                  onChange={(e) => setEmail(e.target.value)}
                />
             
          

              <TextField
                required
                id="password"
                label="password"
                type="password"
                sx={{ background: "#fff", marginTop: 1 }}
                size="small"
                className="w-[200px]"
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className="flex flex-row text-blue-400 text-center text-[10px]">
                <p>
                  <Link to={'/signupeithemailandpassword'}>
                    Kein Konto
                  </Link>
                </p>
                <p>Kennwort vergessen</p>
              </div>
              <button
                className="uppercase bg-[#2EC4B6] w-[200px]
               rounded-md font-medium my-3 mx-auto py-2 text-black"
                onClick={logIn}
              >
                Anmelden
              </button>
              
            </form>

            </div>
          <div className="w-1/2">
            <img
              className="sm:block hidden rounded-2xl"
              src={cleaningImg}
              alt="#"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default SignUp;
