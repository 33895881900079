import Hero from "../components/ui/Hero";
import videomp from "../ressources/Videos/HM_Landscapefilm_15 Neueröffnung.mp4";
import insta from "../ressources/insta2.mp4";
import Services from "./Services";
import Banner from "../components/ui/Banner";
export const Video = () => {
  return (
    <video
    className="aspect-auto bg-cover"
    muted
  
    loop={true}
  >
    <source src={insta} type="video/mp4" />
  </video>
  )
}
const HomePage = () => {
  return (
    <>
      <Hero />
      <div className="w-full">
        <video
          className="aspect-auto bg-cover w-full"
          muted
          autoPlay={true}
        
          loop={true}
        >
          <source src={videomp} type="video/mp4" />
        </video>
      
      </div>

      <Services bg={"bg-[#f1f2f6]"} />
      <Banner/>
    </>
  );
};

export default HomePage;
