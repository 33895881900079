import { useAtom } from "jotai";
import React from "react";
import { authUser } from "../../../stores/authAtom";

import Lottie from "lottie-react";
import {
  addDoc,
  collection,
  updateDoc,
  onSnapshot,
  doc,
} from "firebase/firestore";
import { db } from "../../../firebase.config";
import { Customer, Service } from "../../../models/Service";
import serviceCardAtom, {
  customerAtom,
  customerInfoExists,
  phoneLogedIn,
} from "../../../stores/serviceCardAtom";
import animation from "../../../ressources/lotti/sent.json";

import uuid from "react-uuid";
import { anonymUserEmail } from "./SigninWithLink";
import { error } from "console";
import { addToCustomerCollection } from "../../../helpers/firestoreHelper";
import { useToast } from "@chakra-ui/react";
import { boolean } from "yup";

const UserNameAndAddress = (props: any) => {
  const toast = useToast();
  const errorToast = useToast();
  const [user, setUser] = useAtom(authUser);

  const [logedInCustomer, setLogedInCustomer] = useAtom(customerAtom);

  const [userInfoxists, setUserInfo] = useAtom(customerInfoExists);
  const [isAnonymous, setisAnonymous] = React.useState(user?.isAnonymous);
  const [email, setEmail] = React.useState(user?.email as string);

  const [customers, setCustomers] = React.useState<Array<Customer>>([]);
  const customerQuery = collection(db, "customer");

  const [customer, setCustomer] = React.useState<Customer>({
    id: uuid(),
    docId: null,
    firstname: "",
    lastname: "",
    address: "",
    doornumber: "",
    postalcode: "2384",
    city: " Breitenfurt bei Wien",
    phonenumber: user?.phoneNumber as string,
    landlinenumber: "",
    email: email,
  });
  const update = async () => {
    const foundCustomerDate = doc(db, "customer", customer?.docId as string);

    await updateDoc(foundCustomerDate, {
      firstname: customer.firstname,
      lastname: customer.lastname,
      address: customer.address,
      doornumber: customer.doornumber,
      postalcode: "2384",
      city: "Breitenfurt bei Wien",
      phonenumber: customer.phonenumber,
      landlinenumber: customer.landlinenumber,
      email: email,
    });
  };

  React.useEffect(() => {
    const unSub = onSnapshot(customerQuery, (query) => {
      let customerData: Customer[] = [];
      query.forEach((doc) => {
        customerData.push({ docId: doc.id, ...doc.data() } as Customer); // Cast to Order type
      });

    
      let cu: Customer | undefined;
     
        cu = customers.find((x) => x.email == email);
      
        setCustomers(customerData);
       

      if (
        cu?.firstname == null ||
        cu?.lastname == null ||
        cu?.address == null ||
        cu?.doornumber == null ||
        cu?.firstname === undefined ||
        cu?.lastname === undefined ||
        cu?.address === undefined ||
        cu?.doornumber === undefined
      ) {
    
        setUserInfo(false);
      } else {
       
        setUserInfo(true);
      }
    });
    return () => unSub();
  }, [user, userInfoxists]);
  React.useEffect(() => {
  
      const cu = customers.find((x) => x.email == email);
      setCustomer(cu as Customer);
      setLogedInCustomer(cu as Customer);
    

    if (
      customer?.firstname == null ||
      customer?.lastname == null ||
      customer?.address == null ||
      customer?.doornumber == null ||
      customer?.firstname === undefined ||
      customer?.lastname === undefined ||
      customer?.address === undefined ||
      customer?.doornumber === undefined
    ) {
      setUserInfo(false);
    } else {
      console.log(userInfoxists);
      setUserInfo(true);
    }
  }, [customers]);
  const saveHandler = () => {
    console.log(customer);
    if (userInfoxists == false) {
      addToCustomerCollection({
        id: uuid(),
        docId: null,
        firstname: customer?.firstname,
        lastname: customer?.lastname,
        address: customer?.address,
        doornumber: customer?.doornumber,
        postalcode: "2384",
        city: "Breitenfurt bei Wien",
        phonenumber:
          customer?.phonenumber == undefined
            ? "unbekannt"
            : customer?.phonenumber,
        landlinenumber:
          customer?.landlinenumber == undefined
            ? "unbekannt"
            : customer?.landlinenumber,
        email: email,
      })
        .then(() => {
          toast({
            title: "Personal Info",
            description: " Ihre Personal Datei wurde gespeichert",
            status: "success",
            duration: 9000,
            isClosable: true,
            position: "top-right",
          });
          setUserInfo(true);
        })
        .catch((err) => {
          console.log(err);
          errorToast({
            title: "Fehler",
            description:err.message,
              // "Bitte füllen Sie alle Pflichtfelder aus, bevor Sie Ihre Informationen absenden.",
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top-right",
          });
        });
    } else {
      update()
        .then(() => {
          toast({
            title: "Updated",
            description: "Ihre Info wurde Aktualisiert.",
            status: "success",
            duration: 9000,
            isClosable: true,
            position: "top-right",
          });
        })
        .catch((err) => {
          errorToast({
            title: "Fehler",
            description:
              "Bitte füllen Sie alle Pflichtfelder aus, bevor Sie Ihre Informationen absenden.",
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top-right",
          });
        });
    }
  };
  return (
 <div className="md:h-[100vh] h-auto">
    <div className="grid gap-6 mb-6 md:grid-cols-2">
      <div>
        <div />
        <label
          htmlFor="first_name"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Vorname* (Pflichtfelder)
        </label>
        <input
          type="text"
          id="first_name"
          value={customer?.firstname}
          onChange={(e) => {
            setCustomer({ ...customer, firstname: e.target.value });
          }}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Max"
          required
        />
      </div>
      <div>
        <label
          htmlFor="last_name"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Nachname* (Pflichtfelder)
        </label>
        <input
          type="text"
          value={customer?.lastname}
          onChange={(e) => {
            setCustomer({ ...customer, lastname: e.target.value });
          }}
          id="last_name"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Mustermann"
          required
        />
      </div>
      <div>
        <label
          htmlFor="address"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Adresse* (Pflichtfelder)
        </label>
        <input
          type="text"
          id="address"
          value={customer?.address}
          onChange={(e) => {
            setCustomer({ ...customer, address: e.target.value });
          }}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Musterstraße 121"
          required
        />
      </div>
      <div>
        <label
          htmlFor="door"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Nummer* (Pflichtfelder)
        </label>
        <input
          type="text"
          id="door"
          value={customer?.doornumber}
          onChange={(e) => {
            setCustomer({ ...customer, doornumber: e.target.value });
          }}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="12"
          required
        />
      </div>
      <div>
        <label
          htmlFor="post"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Postleitzahl
        </label>
        <input
          type="text"
          id="post"
          value={"2384"}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="2384"
          readOnly
        />
      </div>
      <div>
        <label
          htmlFor="city"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Ort
        </label>
        <input
          type="text"
          id="city"
          value={"Breitenfurt bei Wien"}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Breitenfurt bei Wien"
          readOnly
        />
      </div>
      <div>
        <label
          htmlFor="phone"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Handynummer
        </label>
        <input
          type="tel"
          id="phone"
          readOnly={!user?.isAnonymous}
          value={customer?.phonenumber as string}
          onChange={(e) => {
            setCustomer({ ...customer, phonenumber: e.target.value });
          }}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="0644123456"
        />
      </div>
      <div>
        <label
          htmlFor="landline"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Festnetz
        </label>
        <input
          type="tel"
          id="landline"
          value={customer?.landlinenumber as string}
          onChange={(e) => {
            setCustomer({ ...customer, landlinenumber: e.target.value });
          }}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="123-45-678"
          pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
        />
      </div>
    </div>
    <div className="mb-6">
      <label
        htmlFor="email"
        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
      >
        Email adresse
      </label>
      <input
        type="email"
        id="email"
        readOnly={!user?.isAnonymous}
        value={customer?.email as string}
        onChange={(e) => {
          console.log(customer?.email);
          setCustomer({ ...customer, email: e.target.value });
        }}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
        focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
         dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder="max@mustermann.com"
      />
    </div>
    <div className="flex flex-row justify-start h-[80px]">
      <div>
        <button
          type="submit"
          onClick={saveHandler}
          className=" text-white h-[60%] bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg
          dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
        >
          Speichern
        </button>
      </div>
      <div className="h-[10px] w-[100px] ">
   
      </div>
    </div>
  </div>
   
  );
};

export default UserNameAndAddress;
