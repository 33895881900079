import { query,collection,where,doc,getDocs, DocumentData,addDoc } from "firebase/firestore";
import { db } from "../firebase.config";
import { Customer, Service } from "../models/Service";

const ordersCollectionRef = collection(db, "orders");
const customerCollectionRef = collection(db, "customer");

const addToOrdersCollection=async(service:Service)=>{
    await addDoc(ordersCollectionRef,{id:service.id,type:service.type,service:service.service,
    date:service.date,time:service.time,specification1:service.specification1,specification2:service.specification2,
    specification3:service.specification3,specification4:service.specification4,specification5:service.specification5,
    specification6:service.specification6})
      }

    export  const addToCustomerCollection=async(service:Customer)=>{
        await addDoc(customerCollectionRef,{id:service.id,
          firstname:service.firstname,lastname:service.lastname,address:service.address,doornumber:service.doornumber,
          postalcode:service.postalcode,city:service.city,phonenumber:service.phonenumber,
          landlinenumber:service.landlinenumber, email:service.email})
          }