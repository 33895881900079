
import React from 'react'
import { useAtom } from "jotai";
import { authUser } from "../stores/authAtom";
import { useNavigate } from "react-router-dom";

import serviceCardAtom,{customerAtom} from "../stores/serviceCardAtom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box } from '@mui/material';
import { db } from "../firebase.config";
import { Service,Customer } from "../models/Service";
import { DocumentData, collection, getDocs, query, where } from 'firebase/firestore';
import OrderDetails from '../components/ui/components/Dialogs/OrderDetails';
import NoAddressAlert from '../components/ui/components/Alerts/NoAddressAlert';
import UserNameAndAddress from '../components/ui/Forms/UserNameAndAddress';
import { anonymUserEmail } from '../components/ui/Forms/SigninWithLink'; 

const UserDashboard = () => {
  const[logedInCustomer,setLogedInCustomer]=useAtom(customerAtom);
  const[anonymEmail,setAnonymEmail]=useAtom(anonymUserEmail);
  const[user,setUser]=useAtom(authUser);

  //start form useState
  const [email, setEmail] = React.useState( user?.isAnonymous ? anonymEmail : user?.email as string);
  console.log(email)
  //end form useState
  const [services, setService] = useAtom(serviceCardAtom);

  
  const [value, setValue] = React.useState('1');
   const ordersCollectionRef = collection(db, "orders");
 const[orders,setOrders]=React.useState<Array<Service>|null >(null)
 const[customer,setCustomer]=React.useState<Array<Customer>|null >(null)
 const[formCkeck,setFormCheck]=React.useState(false)
 const q = query(collection(db, "orders"), where("customeremail", "==",user?.isAnonymous ? anonymEmail : user?.email as string))

 React.useEffect(()=>{

   const loadOrders=async ()=>{
     let p= await getDocs(ordersCollectionRef);

     
const querySnapshot = await getDocs(q);

const ordersData: Service[] = [];

querySnapshot.forEach((doc) => {
  ordersData.push({ id: doc.id, ...doc.data() } as Service); // Cast to Order type
});
setOrders(ordersData)
   }
   loadOrders()


   },[])
   React.useEffect(()=>{
   
    
    if(logedInCustomer){

      
     setFormCheck(true)

     
    }else{

      
    }
   
   },[orders,logedInCustomer])
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
  <>
   
 
<section
        className="bg-[#f7f7f7] min-h-screen flex items-center w-[100%] h-[100%]
justify-center">
 
    <div className="bg-[#f7f8f7]  rounded-2xl shadow-lg
         p-5 w-[80%] min-h-[600px] ">
           {formCkeck ? <p></p>  :<NoAddressAlert/>}
          
  <TabContext value={value}  >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example" >
            <Tab label="Daten" value="1" />
            <Tab label="Offene Termine" value="2" />
            {/* <Tab label="Abgeschlossene Termine" value="3" /> */}
          </TabList>
        </Box>
        <TabPanel value="1">
   <UserNameAndAddress />
        </TabPanel>
        <TabPanel value="2">
        <TableContainer component={Paper}  sx={{height:'100vh',overflowY:'scroll' }}>
     
     <Table sx={{ minWidth: 650}} aria-label="simple table">
      <TableHead>
       <TableRow
       >
           <TableCell align="center">Leistungtitel</TableCell>
           <TableCell align="center">Rubrik</TableCell>
           <TableCell align="center" >Datum</TableCell>
           <TableCell align="center" >Zeitfenster</TableCell>
           <TableCell align="center" >Details</TableCell>
        
       </TableRow>
       </TableHead>
       <TableBody>
        {orders?.map((order : DocumentData)=>(
         <TableRow
         key={order.id}
         sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
       >
         <TableCell align="center">{order.service}</TableCell>
         <TableCell align="center">{order.type}</TableCell>
         <TableCell align="center" >{order.date}</TableCell>
         <TableCell align="center" >{order.time}</TableCell>
         <TableCell align="center" >
          <OrderDetails order={order}/>
         </TableCell>
       </TableRow>
 
       ))} 
       
       </TableBody>
     
     </Table>
   </TableContainer>
        </TabPanel>
        <TabPanel value="3">Item Three</TabPanel>
      </TabContext>
</div>
</section>
</>
  )
}

export default UserDashboard