import React from 'react'
import image from '../../../../ressources/images/bruno-kelzer-LvySG1hvuzI-unsplash.jpg'

import ShoppingRequest from '../Dialogs/ShoppingRequest'

const Shopping = () => {

  const [open, setOpen] = React.useState(false);
  function modalHandler(e:any){
    setOpen(!open)
  }
  function cloneModal(){ 
    setOpen(false)
  }
  return (

    <div onClick={modalHandler}   className="flex flex-col  hover:bg-[#f1f2f6] cursor-pointer rounded-lg  
     hover:scale-105  duration-300  bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] 
    dark:bg-neutral-700 md:min-w-[80%] md:max-w-[80%] md:flex-row ">  
    <img
      className="h-96  w-full rounded-t-lg  md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
      src={image}
      alt=""
    />
    <div className="flex flex-col justify-start py-6 px-3 md:w-[60%] ">
      <h5 className="mb-2 text-xl font-medium text-neutral-800 dark:text-neutral-50">
      Einkaufsservice
      </h5>
      <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200 mt-5 md:min-w-[100%]">
      Wir helfen Ihnen bei Ihren Besorgungen Ihres alltäglichen Bedarfs - 
       wir fahren mit Ihnen einkaufen oder kümmern uns um Ihre Einkaufsliste.
      </p>
 
     
    </div>
  <div className="flex  md:mt-[20px] ">
<ShoppingRequest  openDialog={open} onModalHandler={cloneModal}/>
      </div>
  </div>
  )
}

export default Shopping
