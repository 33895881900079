import React from "react";
import { TypeAnimation } from "react-type-animation";
import { useNavigate } from "react-router-dom";
const ExampleComponent = () => {
  return (
    <TypeAnimation
      sequence={[
        "Helfende Hände",
        2000,
        "Reinigung",
        1000,
        "Gartenarbeit",
        1000,
      ]}
      wrapper="span"
      cursor={true}
      repeat={Infinity}
      style={{ fontSize: "2em", display: "inline-block" }}
    />
  );
};

const Hero = () => {
  const navigate = useNavigate();
  return (
    <div className="text-[#011627] py-[40px]">

      <div className="max-w-[800px]  w-full h-[40vh] mx-auto text-center flex flex-col justify-center">
        <p className="text-[#5A4B40] font-bold p-1">
          Herzlich willkommen bei den HausmännerInnen
        </p>
        <h1 className="md:text-7xl sm:text-6xl text-4xl font-bold md:py-4">
          Ab 15. September
        </h1>
        <div>
          <p className="md:text-4xl sm:text-3xl text-xl p-1 font-bold">
            Woran sind Sie interessiert?
          </p>
        </div>
        <div className="mt-3">
          <ExampleComponent />
        </div>
        <div className="flex justify-center content-center ma">
          <button
            onClick={() => {
              navigate("/services");
            }}
            className="uppercase  bottom-3 shadow-lg shadow-[#b1c2a3] bg-gradient-to-r from-[#C0CDB5] via-[#a4b496] to-[#a4b496] hover:bg-gradient-to-br  active:bg-[#92a583]  md:align-middle   bg-[#C0CDB5] w-[300px] rounded-md font-medium my-6 md:mx-auto  py-3 text-black"
          >
            Jetzt Angebot anfordern
          </button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
