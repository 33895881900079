import GarbageService from '../components/ui/components/cards/GarbageService';
import HandWorkService from '../components/ui/components/cards/HandWorkService';
import HouseSetting from '../components/ui/components/cards/HouseSetting';
import PetSitting from '../components/ui/components/cards/PetSitting';
import Shopping from '../components/ui/components/cards/Shopping'
const HelpingHands = () => {
  return (
    <div className="w-full py-[3rem] flex gap-10 flex-col lg:items-center justify-items-stretch px-4 bg-white ">
  <GarbageService/>
 <HandWorkService/>
<HouseSetting/>
<PetSitting/>
<Shopping/>

    </div>
  )
}

export default HelpingHands