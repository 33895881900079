import React from 'react'
import { FcKey,FcGoodDecision,FcIphone } from "react-icons/fc";
import {useNavigate} from 'react-router-dom'
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import { useAtom } from 'jotai';
import { authUser } from '../../../../stores/authAtom';
const GuestButton = () => {
  const navigation=useNavigate()
  const auth = getAuth();
  const [userAtom, setUserAtom] = useAtom(authUser);
  return (
    
    <button type="button"
    onClick={()=>{
      signInAnonymously(auth)
      .then((u) => {
        setUserAtom(u.user);
        navigation('/dashboard')
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
      });
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.email;
      } else {
        // User is signed out
        // ...
      }
    });
 
    }}
     className="text-white  bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br
      focus:ring-4 focus:outline-none focus:ring-cyan-300  font-medium rounded-lg text-md px-5 py-2.5 text-center
       inline-flex items-center dark:focus:ring-[#bfa66f] mr-2 mb-2 w-[280px]">
         <FcGoodDecision size={25} className='mr-5 h-10'/>
Als Gast 

  </button>
  )
}

export default GuestButton
