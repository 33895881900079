import { useAtom } from "jotai";
import React from "react";
import { authUser } from "../../../stores/authAtom";

import Lottie from "lottie-react";
import {
  addDoc,
  collection,
  updateDoc,
  onSnapshot,
  doc,
} from "firebase/firestore";
import { db } from "../../../firebase.config";
import { Customer, Service } from "../../../models/Service";

import animation from "../../../ressources/lotti/sent.json";

import uuid from "react-uuid";

import { addToCustomerCollection } from "../../../helpers/firestoreHelper";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { customerInfoSchema } from "../../../schema";
import serviceCardAtom from "../../../stores/serviceCardAtom";
import OrderFinishDialog from "../components/Dialogs/OrderFinishDialog";

const AnonymForm = (props: any) => {
    const navigate=useNavigate()
    const [open, setOpen] = React.useState(false);
  const toast = useToast();
  const [services, setService] = useAtom(serviceCardAtom);
  const errorToast = useToast();
  const ordersCollectionRef = collection(db, "orders");
  const mailCollectionRef = collection(db, "mail");
  const customerQuery = collection(db, "customer");
  const addToDatabase = async (service: Service) => {
    await addDoc(ordersCollectionRef, {
      id: service.id,
      type: service.type,
      service: service.service,
      date: service.date,
      time: service.time,
      specification1: service.specification1,
      specification2: service.specification2,
      specification3: service.specification3,
      specification4: service.specification4,
      specification5: service.specification5,
      specification6: service.specification6,
      customer: {
        firstname: values.firstName,
        lastname: values.lastName,
        address: values.address,
        doornumber: values.doornumber,
        postalcode: "2384",
        city: "Breitenfurt bei Wien",
        phonenumber: values.phonenumber,

        landlinenumber: values.landline === "" ? "unbekannt" : values.landline,
        email: values.email === "" ? "unbekannt" : values.email,
      },
      status: "Öffnen",
    });
  };
  function modalHandler(e: any) {
    setOpen(!open);
  }
  function cloneModal() {
    setOpen(false);
  }
  const saveHandler = (e: any) => {
    e.preventDefault();
  if(services.length==0){
    errorToast({
        title: "Fehler",
        description: "Wählen Sie ein Service",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
      return;
  }else if(values.firstName==''||values.lastName==''|| values.address==''||values.doornumber==''||values.phonenumber==''){
 
    errorToast({
        title: "Fehler",
        description: "Bitte füllen Sie alle Pflichtfelder aus, bevor Sie Ihre Informationen absenden.",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
  return;
  }

    addToCustomerCollection({
      id: uuid(),
      docId: null,
      firstname: values.firstName,
      lastname: values.lastName,
      address: values.address,
      doornumber: values.doornumber,
      postalcode: "2384",
      city: "Breitenfurt bei Wien",
      phonenumber: values.phonenumber,

      landlinenumber: values.landline === "" ? "unbekannt" : values.landline,
      email: values.email === "" ? "unbekannt" : values.email,
    })
      .then(() => {
        services.map((service: Service) => {
          addToDatabase(service);
        });
        addTomailDatabase().then(()=>{
            setOpen(!open)
        });
     
      })
      .catch((err) => {
    
        errorToast({
          title: "Fehler",
          description: err.message,
        
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
      });
    const addTomailDatabase = async () => {
      await addDoc(mailCollectionRef, {
        to:
          values.email === ""
            ? ["service@diehausmaennerinnen.at"]
            : [values?.email, "service@diehausmaennerinnen.at"],
        message: {
          subject: "Anfrage",
          body: "Sehr geehrte Kunde",
          html: emailBody,
        },
      })
        .then(() => {
       
        })
        .catch((err) => {
      
        });
    };
  };

  const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      address: "",
      doornumber: "",
      phonenumber: "",
      landline: "",
      email: "",
    },
    validationSchema: customerInfoSchema,
    onSubmit: saveHandler,
  });
  const emailBody = `
    <html>

    <head>
      <style>
        table {
          border-collapse: collapse;
          width: 100%;
        }
        th, td {
          border: 1px solid black;
          padding: 8px;
        }
        a {
          color: blue;
          text-decoration: underline;
        }
        .contact-info {
         
          
        }
      </style>
    </head>
      <body>
      <h5>Sehr geehrte/r ${values.firstName} ${values.lastName},</h5>
      <p>vielen Dank für Ihre Anfrage über unsere Website. Wir haben Ihre Anfrage erhalten
       und freuen uns über Ihr Interesse an unseren Dienstleistungen. </p>
     
      <p>Unser Team wird sich in Kürze mit Ihnen in Verbindung setzen, um einen Termin zu vereinbaren. Dabei dokumentieren
       wir Ihre individuellen Wünsche um Ihnen ein unschlagbares Angebot machen zu können. </p>
     
      <p>Vielen Dank für Ihr Vertrauen. Wir freuen uns darauf, Ihnen weiterhelfen zu können.</p>
      
     
      <br/>
      <br/>
      <br/>
      <br/>
      <p>Mit freundlichen Grüßen</p>
      <p>Das Hausmännerinnen-Team</p>
      <p>Die Hausmännerinnen</p>
      <div class="contact-info">
      <p>Tel: <a href="tel:+436606200600">0660 / 6 200 600</a></p>
      <p>Email: <a href="mailto:service@diehausmaennerinnen.at">service@diehausmaennerinnen.at</a></p>
      <p>Website: <a href="https://xn--diehausmnnerinnen-xqb.at/">diehausmännerinnen.at</a></p>
    </div>
    <hr/>

    <h1>Anfrage</h1>
      <ul>
      <li> Vorname: ${values.firstName}</li>
      <li> Nachname:  ${values.lastName}</li>
      <li> Adresse: ${values.address}</li>
      <li> Türnummer: ${values.doornumber}</li>
      <li> Postleitzahl: 2384</li>
      <li> Ort: Breitenfurt bei Wien</li>
      <li> Handynummer: ${values.phonenumber}</li>
      <li> Festnetz: ${values?.landline}</li>
      <li> Email adresse: ${values?.email}</li>
      </ul>
       
        <table >
          <tr>
            <th>ID</th>
            <th>Type</th>
            <th>Service</th>
            <th>Spezifikation</th>
            <th>Spezifikation</th>
            <th>Spezifikation</th>
            <th>Spezifikation</th>
            <th>Spezifikation</th>
            <th>Spezifikation</th>
            <th>Wunschtermin</th>
            <th>Zeit Fenster</th>
          </tr>
          ${services
            .map(
              (service) => `
            <tr>
              <td>${service.id ?? ""}</td>
              <td>${service.type}</td>
              <td>${service.service}</td>
              <td>${service.specification1}</td>
              <td>${service.specification2 ?? ""}</td>
              <td>${service.specification3 ?? ""}</td>
              <td>${service.specification4 ?? ""}</td>
              <td>${service.specification5 ?? ""}</td>
              <td>${service.specification6 ?? ""}</td>
              <td>${service.date}</td>
              <td>${service.time}</td>
            </tr>
          `
            )
            .join("")}
        </table>
      
       
      </body>
    </html>
  `;
  return (
    <section
      className="bg-[#f7f7f7] min-h-screen flex items-center w-[100%] h-[100%]
justify-center"
    >
      <div
        className="bg-[#f7f8f7]  rounded-2xl shadow-lg
         p-5 w-[80%] min-h-[600px] "
      >
        <div className="md:h-[100vh] h-auto m-10 bg-[#f7f7f7]">
          <form onSubmit={saveHandler}>
            <div className="grid gap-6 mb-6 md:grid-cols-2">
              <div>
            
                <label
                  htmlFor="firstName"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Vorname* (Pflichtfelder)
                </label>
                <input
                  type="text"
                  id="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  className={
                    errors.firstName
                      ? "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 "
                      : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5 "
                  }
                  placeholder="Max"
                />
                {errors.firstName && (
                  <p className="text-red-500 text-xs mt-1">
                    Bitte füllen Sie alle erforderlichen Felder aus
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="lastName"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Nachname* (Pflichtfelder)
                </label>
                <input
                  type="text"
                  value={values.lastName}
                  onChange={handleChange}
                  id="lastName"
                  className={
                    errors.lastName
                      ? "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full  p-2.5 "
                      : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  }
                  placeholder="Mustermann"
                />
                {errors.lastName && (
                  <p className="text-red-500 text-xs mt-1">
                    Bitte füllen Sie alle erforderlichen Felder aus
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="address"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Adresse* (Pflichtfelder)
                </label>
                <input
                  type="text"
                  id="address"
                  value={values.address}
                  onChange={handleChange}
                  className={
                    errors.address
                      ? "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full  p-2.5 "
                      : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  }
                  placeholder="Musterstraße 121"
                />
                {errors.address && (
                  <p className="text-red-500 text-xs mt-1">
                    Bitte füllen Sie alle erforderlichen Felder aus
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="doornumber"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Nummer* (Pflichtfelder)
                </label>
                <input
                  type="text"
                  id="doornumber"
                  value={values.doornumber}
                  onChange={handleChange}
                  className={
                    errors.doornumber
                      ? "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full  p-2.5 "
                      : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5  "
                  }
                  placeholder="12"
                />
                {errors.doornumber && (
                  <p className="text-red-500 text-xs mt-1">
                    Bitte füllen Sie alle erforderlichen Felder aus
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="post"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Postleitzahl
                </label>
                <input
                  type="text"
                  id="post"
                  value={"2384"}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="2384"
                  readOnly
                />
              </div>
              <div>
                <label
                  htmlFor="city"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Ort
                </label>
                <input
                  type="text"
                  id="city"
                  value={"Breitenfurt bei Wien"}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Breitenfurt bei Wien"
                  readOnly
                />
              </div>
              <div>
                <label
                  htmlFor="phonenumber"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Handynummer* (Pflichtfelder)
                </label>
                <input
                  type="tel"
                  id="phonenumber"
                  value={values.phonenumber as string}
                  onChange={handleChange}
                  className={
                    errors.phonenumber
                      ? "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full  p-2.5 "
                      : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5 "
                  }
                  placeholder="0644123456"
                />
                {errors.phonenumber && (
                  <p className="text-red-500 text-xs mt-1">
                    Bitte füllen Sie alle erforderlichen Felder aus
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="landline"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Festnetz
                </label>
                <input
                  type="tel"
                  id="landline"
                  value={values.landline as string}
                  onChange={handleChange}
                  className={
                    errors.landline
                      ? "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full  p-2.5 "
                      : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5 "
                  }
                  placeholder="123-45-678"
                  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                />
              </div>
            </div>
            <div className="mb-6">
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Email adresse
              </label>
              <input
                type="email"
                id="email"
                value={values.email as string}
                onChange={handleChange}
                className={
                  errors.email
                    ? "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full  p-2.5 "
                    : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5 "
                }
                placeholder="max@mustermann.com"
              />
            </div>
            <div className="flex flex-row justify-start h-[80px]">
              <div>
                <button
                  type="submit"
                  onClick={saveHandler}
                  className=" text-white h-[60%] bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg
          dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                >
                  Anfrage Senden
                </button>
              </div>
              <div className="h-[10px] w-[100px] "></div>
            </div>
          </form>
        </div>
        <OrderFinishDialog openDialog={open} onModalHandler={cloneModal} />
      </div>
     
    </section>
  );
};

export default AnonymForm;
