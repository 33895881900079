import * as yup from 'yup'

export const signupSchema=yup.object().shape({
    firstName:yup.string().required(),
    lastName:yup.string().required(),
    email:yup.string().email("bitte geben Sie ein Gültiges Email").required(),
    password: yup.string().required('Password is required'),
    passwordConfirmation: yup.string()
    .test('password-should-match', 'Passwords must match', function(value){
      return this.parent.password === value
    })
})

export const IroningSchema=yup.object().shape({
  hours:yup.number().positive().integer().required(),
 interval:yup.string().required(),
 date:yup.string().required(),
 time:yup.string().required(),
 time2:yup.string().required()})
 
export const windowCleaningSchema=yup.object().shape({
 numberWindow:yup.number().positive().integer().required(),
sides:yup.string().required(),
frame:yup.string().required(),
sunProtector:yup.string().required(),
interval:yup.string().required(),
date:yup.string().required(),
time:yup.string().required(),
time2:yup.string().required()})
export const anonymSignupSchema=yup.object().shape({
  email:yup.string().email().required(),
})
export const roomCleaningSchema=yup.object().shape({
  size:yup.number().positive().integer().required(),
  floor:yup.number().positive().integer().required(),
  toilet:yup.number().positive().integer().required(),
  kitchen:yup.number().positive().integer().required(),
  cleaningSupplyAvailibility:yup.string().required(),
  interval:yup.string().required(),
  date:yup.string().required(),
  time:yup.string().required(),
  time2:yup.string().required()
})
export const laundrySchema=yup.object().shape({
  numberOfWash:yup.number().positive().integer().required(),
  interval:yup.string().required(),
  date:yup.string().required(),
  time:yup.string().required(),
  time2:yup.string().required()
})
const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g
export const customerInfoSchema=yup.object().shape({
  firstName:yup.string().required(),
  lastName:yup.string().required(),
  address:yup.string().required(),
  doornumber:yup.string().required(),
  phonenumber:yup.string().required("required")
  .matches(phoneRegExp, 'Phone number is not valid')
  .min(4, "too short")
  .max(100, "too long"),
  landline:yup.string()
  .matches(phoneRegExp, 'Phone number is not valid')
  .min(4, "too short")
  .max(100, "too long"),
  email:yup.string().email("bitte geben Sie ein Gültiges Email"),

})
export const jobApplicationInfoSchema=yup.object().shape({
  firstName:yup.string().required(),
  lastName:yup.string().required(),
  phonenumber:yup.string().required("required")
  .matches(phoneRegExp, 'Phone number is not valid')
  .min(4, "too short")
  .max(100, "too long"),
  email:yup.string().email("bitte geben Sie ein Gültiges Email"),
  hours:yup.string().required(),
})