import React from 'react'
import {useNavigate} from 'react-router-dom'
import { FcKey,FcUnlock } from "react-icons/fc";
const EmailButton = () => {
  const navigation=useNavigate()
  return (
    <button type="button"
    onClick={()=>{
      navigation('/signinwithemail')
    }}
     className="text-white  bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 hover:bg-gradient-to-br 
     focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-md px-5 py-2.5 text-center
      inline-flex items-center dark:focus:ring-[#bee4bf] mr-2 mb-2 w-[280px]">
   <FcKey size={25} className='mr-5 h-10'/>

    Mit Email und Kennwort
  </button>
  )
}

export default EmailButton