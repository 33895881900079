import React from "react";
import {
  FaDribbbleSquare,
  FaFacebookSquare,
  FaGithubSquare,
  FaInstagramSquare,
  FaTwitterSquare,
} from "react-icons/fa";
import logo from "../../ressources/images/logo.png";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div className="max-w-[1240px]   mx-auto py-5 px-4 flex flex-col text-[#011627] ">
      <div>
        <div className="w-full  flex justify-center ">
          <Link to={"/"}>
            <img src={logo} className="w-[400px] mt-1" />
          </Link>
        </div>
      </div>
   
      <div className="  flex md:justify-between justify-center text-xs md:text-sm md:mx-[100px] mt-6 ml-2 ">
       
        <div className=" mr-5 md:mr-0">
          <Link to={"/"}>Home</Link>
        </div>
        <div className=" mr-5 md:mr-0">
          <Link to={"/services"}> Services</Link>
        </div>
        <div className=" mr-5 md:mr-0"> 
          <Link to="./contactus">Kontakt</Link>
        </div >
        <div className=" mr-5 md:mr-0">
          <Link to={"/aboutus"}> Über Uns</Link>
        </div>
        {/* <div className=" mr-2 md:mr-0">
          <Link to={"/signinmethods"}> Anmelden</Link>
        </div> */}
        <div className=" mr-5 md:mr-0">
          <Link to={"/servicecard"}> Warenkorb</Link>
        </div>
      </div>
      <div className=" flex justify-center   mt-10 border-t border-t-gray-300 ">
        <div className="mr-10 mt-5 uppercase">
          <Link to={"/contactus"}> Impressum</Link>
        </div>
        <div className="mr-10 mt-5">
          <Link to="./agb">AGB</Link>
        </div>
        <div className="mt-5">
          <Link to={"/dgpr"}> Datenschutz</Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
