
import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from '../components/global/Navbar'
import Footer from '../components/global/Footer'

const MainLayout = () => {
  return (
   <React.Fragment>
    <Navbar/>
    <Outlet/>
    <Footer/>
   </React.Fragment>
  )
}

export default MainLayout