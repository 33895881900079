import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Lottie from "lottie-react";
import animation from "../../../../ressources/lotti/sent.json";
import { useAtom } from "jotai";
import serviceCardAtom from "../../../../stores/serviceCardAtom";
import { useNavigate } from "react-router-dom";
import { Alert, Typography } from "@mui/material";



const OrderFinishDialog = (props:any) => {
const navigate= useNavigate()
    const [services, setService] = useAtom(serviceCardAtom);
    const handleClose = () => {
   
        props.onModalHandler()
        services.length=0
        
        setService([]);
        navigate('/')
      };

    const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));





  return (
    <div>

  
      <Dialog
        fullScreen={fullScreen}
        open={props.openDialog}
      
        aria-labelledby="responsive-dialog-title"
        fullWidth={true}
       
       
      >
        <DialogTitle id="responsive-dialog-title">
          <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">
          <Alert severity="success">Anfrage wurde gesendet</Alert>
            
          </h2>
        </DialogTitle>
        <DialogContent>
        <h2 className="mb-4 text-2xl font-bold text-gray-900 dark:text-white">
          Vielen Dank für Ihre Anfrage. Wir werden sie so rasch wie möglich bearbeiten und Ihnen ein Angebot zukommen lassen.
          </h2>
        <Lottie
              animationData={animation}
              loop={false}
             
            />
        </DialogContent>
        <DialogActions>
         
          <Button onClick={handleClose} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OrderFinishDialog;
