import { Alert } from "@mui/material";
import React from "react";

const KontaktUs = () => {
  return (
    <div className="w-full bg-white py-6 px-4 ">
      <div className="container my-24 md:mx-auto md:px-6 w-full">
        <section className="mb-32 ">
          <div className="md:block w-[110%] md:w-full bg-white flex rounded-lg  shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
            <div className="flex flex-wrap  items-center">
              <div className="block w-full shrink-0 grow-0 basis-auto lg:flex lg:w-6/12 xl:w-4/12 mt-6 ">
                <div className="h-[500px] md:w-full w-[200px] ml-2">
                  <iframe
                    className="md:w-full w-[300px]"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2662.674825833818!2d16.182314790108485!3d48.135793977147806!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476da6a09746fd71%3A0x51c44f8df0d6a0b3!2sGeorg%20Sigl-Stra%C3%9Fe%2040%2C%202384%20Breitenfurt%20bei%20Wien!5e0!3m2!1sen!2sat!4v1689616528301!5m2!1sen!2sat"
                    width="400px"
                    height="450"
                    style={{ border: 0 }}
                    loading="lazy"
                    allowFullScreen={false}
                  ></iframe>
                </div>
              </div>
              <div className="w-full shrink-0 grow-0 basis-auto lg:w-6/12 xl:w-8/12 ">
                <div className="flex flex-wrap px-3 pt-12 pb-12 md:pb-0 lg:pt-0 ">
                  <div className="mb-12 w-full shrink-0 grow-0 basis-auto px-3 md:w-6/12 md:px-6 lg:w-full xl:w-6/12 xl:px-12">
                    <div className="flex items-start">
                      <div className="shrink-0">
                        <div className="inline-block rounded-md bg-primary-100 p-4 text-primary">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            className="w-6 h-6 text-gray-800 dark:text-white"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="ml-6 grow">
                        <p className="mb-2 font-bold dark:text-white">
                          Kontakt
                        </p>
                        <p className="text-neutral-500 dark:text-neutral-200  ">
                          <a
                            href="mailto:service@diehausmaennerinnen.at"
                            className="border-b border-b-gray-400 hover:text-blue-400 hover:border-b-blue-400"
                          >
                            service@diehausmaennerinnen.at
                          </a>
                        </p>
                        <p className="text-neutral-500 dark:text-neutral-200">
                          0660 / 6 200 600
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="mb-12 w-full shrink-0 grow-0 basis-auto px-3 md:w-6/12 md:px-6 lg:w-full xl:w-6/12 xl:px-12">
                    <div className="flex items-start">
                      <div className="shrink-0">
                        <div className="inline-block rounded-md bg-primary-100 p-4 text-primary">
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 17 21"
                          >
                            <g
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            >
                              <path d="M8 12a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                              <path d="M13.8 12.938h-.01a7 7 0 1 0-11.465.144h-.016l.141.17c.1.128.2.252.3.372L8 20l5.13-6.248c.193-.209.373-.429.54-.66l.13-.154Z" />
                            </g>
                          </svg>
                        </div>
                      </div>
                      <div className="ml-6 grow">
                        <p className="mb-2 font-bold dark:text-white">
                          Adresse
                        </p>
                        <p className="text-neutral-500 dark:text-neutral-200">
                          Georg Sigl-Straße 40-42
                        </p>
                        <p className="text-neutral-500 dark:text-neutral-200">
                          2384 Breitenfurt bei Wien
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mb-12 w-full shrink-0 grow-0 basis-auto px-3 md:w-6/12 md:px-6 lg:w-full xl:mb-0 xl:w-6/12 xl:px-12">
                    <div className="align-start flex">
                      <div className="shrink-0">
                        <div className="inline-block rounded-md bg-primary-100 p-4 text-primary">
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 18"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 7h9.231M1 11h9.231M13 2.086A5.95 5.95 0 0 0 9.615 1C5.877 1 2.846 4.582 2.846 9s3.031 8 6.769 8A5.94 5.94 0 0 0 13 15.916"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="ml-6 grow">
                        <p className="mb-2 font-bold dark:text-white">
                          Bankverbindung
                        </p>
                        <p className="text-neutral-500 dark:text-neutral-200">
                          IBAN AT09 3200 0000 1101 2234
                        </p>
                        <p className="text-neutral-500 dark:text-neutral-200">
                          BIC RLNWATWW
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-full shrink-0 grow-0 basis-auto px-3 md:w-6/12 md:px-6 lg:w-full xl:w-6/12 xl:px-12">
                    <div className="align-start flex">
                      <div className="shrink-0">
                        <div className="inline-block rounded-md bg-primary-100 p-4 text-primary">
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 20"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="ml-6 grow">
                        <p className="mb-2 font-bold dark:text-white">
                          UID und Firmenbuchnummer
                        </p>
                        <p className="text-neutral-500 dark:text-neutral-200">
                          Österreich:ATU65532667
                        </p>
                        <p className="text-neutral-500 dark:text-neutral-200">
                          FN 342246d, LG Wr Neustadt
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
               
              </div>
              <Alert icon={false} severity="success">
              Verantwortlich für diese Seite: AFUA GmbH, Geschäftsführung Dr. Harald Peterka
</Alert>
             
            </div>
          </div>
        </section>
        
      </div>
    </div>
  );
};

export default KontaktUs;
