import { TextField } from '@mui/material';
import React,{useState} from 'react'
import cleaningImg from "../../../ressources/images/cleaningservicet.png"
import {useCreateUserWithEmailAndPassword}from  "react-firebase-hooks/auth";
import { auth } from '../../../firebase.config';
import { useFormik } from 'formik';
import { signupSchema } from '../../../schema';
import EmailField from '../components/EmailField';
import PasswordInput from '../components/PasswordInput';
import NameInput from '../components/NameInput';
import GoogleButton from '../components/buttons/GoogleButton';
import FacebookButton from '../components/buttons/FacebookButton';
import AppleButton from '../components/buttons/AppleButton';
import EmailButton from '../components/buttons/EmailButton';
import TelephoneButton from '../components/buttons/TelephoneButton';
import GuestButton from '../components/buttons/GuestButton';


const SignUpMethods = () => {
  
    const {values,errors,handleBlur,handleChange,handleSubmit}= useFormik({
        initialValues: {
          firstName: '',
          lastName: '',
          email: '',
          password:'',
          passwordConfirmation:''
        },
       validationSchema:signupSchema,
        onSubmit: values => {
          alert(JSON.stringify(values, null, 2));
        },
      });

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [
    createUserWithEmailAndPassword,
    user,
    loading,
    error,
  ] = useCreateUserWithEmailAndPassword(auth);
 const SignUpHandler= ()=>{
    createUserWithEmailAndPassword(email, password)
 }

  return (
    <>
    <section  className="bg-[#f7f7f7] min-h-screen flex items-center
justify-center">
 <div
          className="bg-[#f7f8f7] flex rounded-2xl shadow-lg
        max-w-3xl p-5">
             <div className="sm:w-1/2 px-16">
            <h2 className="font-bold text-2xl text-[#C0CDB5] mb-10">Anmelden</h2>

           

           
       
             <EmailButton/>
             <TelephoneButton/>
             <GuestButton/>
              
             
             

            <div
              className="mt-10 grid grid-cols-3 items-center
             text-gray-400"
            ></div>
        </div>
        <div className="w-1/2">
            <img
              className="sm:block hidden rounded-2xl"
              src={cleaningImg}
              alt="#"
            />
          </div>
        </div>
    </section>
        </>
  )
}

export default SignUpMethods