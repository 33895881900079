import React from 'react'
import Cleaningman from '../../ressources/images/cleaningman.png'
import { useNavigate } from 'react-router-dom'

const Banner = () => {
  const navigate=useNavigate()
  return (
    <div className='w-full bg-white py-6 px-4 '>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        <img src={Cleaningman} className='w-[500px] mx-auto my-4' alt="/cleaningman" />
        <div className='flex flex-col justify-center '>
          <p className='text-[#C0CDB5] uppercase font-bold text-xl'>
            MITARBEITER GESUCHT
          </p>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold md:py-2 my-5'>Dann suchen wir Dich</h1>
          <p >
                Du bist StudentIn, du hast Zeit und arbeitest gerne mit Menschen?<br/>
                Du bist zu Hause aber möchtest in einem netten Team arbeiten?<br/>
                Du bist Pensionist und du möchtest Dir tolle Urlaube finanzieren?
              </p>
          <button
          onClick={()=>{
            navigate("/jobapplication")
          }}
           className='uppercase text-white bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 w-[300px] rounded-md font-medium md:mt-[80px] my-6 mx-auto py-3 '>
            Mehr erfahren
          </button>
        </div>

      </div>

    </div>
  )
}

export default Banner