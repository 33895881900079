import React from 'react'

import Lottie from "lottie-react";
import animation from '../ressources/lotti/underconstruction.json'
const LottiePage = () => {
  return (
    <div className="w-full  py-[3rem] flex gap-10 flex-col lg:items-center justify-items-stretch px-4 bg-white md:pt-[200px] md:pb-[200px]">
 
    <Lottie animationData={animation} />;
        </div>
  )
}

export default LottiePage