import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useAtom } from "jotai";
import serviceCardAtom from "../../../../stores/serviceCardAtom";
import uuid from 'react-uuid';
import { today, timeNow,timeNowPlusTwo,formatDate} from "../../../../helpers/timehelper";
import { useToast } from '@chakra-ui/react'



const OrderDetails = (props:any) => {
 
 


    const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));




  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
   
  };
  return (
    <div>
      {/* <button
        onClick={handleClickOpen}
        className="uppercase  bottom-3  hover:bg-[#b1c2a3] active:bg-[#92a583]  md:align-middle   bg-[#C0CDB5] w-[200px] rounded-md font-medium my-6 md:mx-auto ml-10 py-3 text-black"
      >
        Details
      </button> */}
      <button  onClick={handleClickOpen} type="button" className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg
       dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 ">Details</button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
      
        aria-labelledby="responsive-dialog-title"
        fullWidth={true}
       
      >
        <DialogTitle id="responsive-dialog-title">
          <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">
            Details
          </h2>
        </DialogTitle>
        <DialogContent>
         <ul >
           <li className="border-b border-b-gray-300 my-5">{props.order.type}</li>
           <li className="border-b border-b-gray-300 my-5">{props.order.service}</li>
           <li className="border-b border-b-gray-300 my-5">{props.order.date}</li>
           <li className="border-b border-b-gray-300 my-5">{props.order.time}</li>
           {props.order.specification1!=="" &&
           <li className="border-b border-b-gray-300 my-5">{props.order.specification1}</li>}
              {props.order.specification2!==null && <li className="border-b border-b-gray-300 my-5">
                {props.order.specification2}</li>}
                {props.order.specification3!==null &&  <li className="border-b border-b-gray-300 my-5">{props.order.specification3}</li>}
                {props.order.specification4!==null &&  <li className="border-b border-b-gray-300 my-5">{props.order.specification4}</li>}
                {props.order.specification5!==null &&  <li className="border-b border-b-gray-300 my-5">{props.order.specification5}</li>}
                {props.order.specification5!==null &&  <li className="border-b border-b-gray-300 my-5">{props.order.specification6}</li>}
           
         </ul>
       
        </DialogContent>
        <DialogActions>
         
          <Button onClick={handleClose} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OrderDetails;
