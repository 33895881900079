import React, { useEffect } from "react";
import { useAtom } from "jotai";
import { authUser } from "../stores/authAtom";
import { useNavigate } from "react-router-dom";
import Image from "../ressources/images/francesca-tosolini-KJFKCed0YKo-unsplash.jpg";
import window from "../ressources/images/windowcleaning.jpg";
import laundry from "../ressources/images/laundry.jpg";
import ironing from "../ressources/images/ironing.jpg";
import plant from "../ressources/images/housePlant.jpg";
import othertypeofcleaning from "../ressources/images/othertypeofcleaning.jpg";
import lawnmowing from "../ressources/images/daniel-watson-8vBpYpTGo90-unsplash.jpg";
import watering from "../ressources/images/harry-grout--BxiBbWR7rA-unsplash.jpg";
import GardenOtherService from "../ressources/images/eco-warrior-princess-TsOeGUwWzWo-unsplash.jpg";
import garbage from "../ressources/images/sven-brandsma-N1iA4RykxtI-unsplash.jpg";

import handwork from "../ressources/images/bermix-studio-iwz5tmhjl7o-unsplash.jpg";
import housesitting from "../ressources/images/norbert-buduczki-kLYHRZvGcwQ-unsplash.jpg";
import pet from "../ressources/images/yerlin-matu-GtwiBmtJvaU-unsplash.jpg";
import shopping from "../ressources/images/bruno-kelzer-LvySG1hvuzI-unsplash.jpg";
import Lottie from "lottie-react";
import animation from "../ressources/lotti/emtyshoppingcart.json";
import serviceCardAtom, {
  customerAtom,
  customerInfoExists,
  phoneLogedIn
} from "../stores/serviceCardAtom";
import {
  query,
  collection,
  where,
  doc,
  getDocs,
  DocumentData,
  addDoc,
} from "firebase/firestore";
import { db } from "../firebase.config";
import { Service } from "../models/Service";
import OrderFinishDialog from "../components/ui/components/Dialogs/OrderFinishDialog";
const ServiceCard = () => {

  const [services, setService] = useAtom(serviceCardAtom);



  const deleteItem = (id: string | null) => {
    const updatedItems = services.filter((item) => item.id !== id);
    setService(updatedItems);
  };

  // useEffect(()=>{

  // })

  const navigate = useNavigate();
  const requestSendHandler = () => {
 

  
      navigate("/userinfo");
     

  
   
   
  };
 

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 pb-24 pt-16 sm:px-6 lg:max-w-7xl lg:px-8">
        {/* <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Warenkorb
        </h1> */}
        {services.length === 0 ? (
          <Lottie animationData={animation} loop={false} />
        ) : (
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            className="mt-12 lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-12 xl:gap-x-16"
          >
            <section aria-labelledby="cart-heading" className="lg:col-span-7">
              <h1 className="mb-5">Preise werden nach Besichtigung und Erfassung des genauen Aufwands berechnet</h1>
              <ul
                role="list"
                className="divide-y divide-gray-200 border-b border-t border-gray-200"
              >
                {services.map((service) => (
                  <li key={service.id} className="flex py-6 sm:py-10">
                    <div className="flex-shrink-0">
                      {service.service === "Wohnraumreinigung" && (
                        <img
                          src={Image}
                          alt="livingroom"
                          className="h-24 w-24 rounded-md object-cover object-center sm:h-48 sm:w-48"
                        />
                      )}
                      {service.service === "Fensterreinigung" && (
                        <img
                          src={window}
                          alt="window"
                          className="h-24 w-24 rounded-md object-cover object-center sm:h-48 sm:w-48"
                        />
                      )}
                      {service.service === "Wäscheservice" && (
                        <img
                          src={laundry}
                          alt="laundry "
                          className="h-24 w-24 rounded-md object-cover object-center sm:h-48 sm:w-48"
                        />
                      )}
                      {service.service === "Bügelservice" && (
                        <img
                          src={ironing}
                          alt="ironing "
                          className="h-24 w-24 rounded-md object-cover object-center sm:h-48 sm:w-48"
                        />
                      )}
                      {service.service === "Hauspflanzen service" && (
                        <img
                          src={plant}
                          alt="plant "
                          className="h-24 w-24 rounded-md object-cover object-center sm:h-48 sm:w-48"
                        />
                      )}
                      {service.service === "Sonderreinigungen" && (
                        <img
                          src={othertypeofcleaning}
                          alt="othertypeofcleaning "
                          className="h-24 w-24 rounded-md object-cover object-center sm:h-48 sm:w-48"
                        />
                      )}
                      {service.service === "Rasen mähen" && (
                        <img
                          src={lawnmowing}
                          alt="lawnmowing "
                          className="h-24 w-24 rounded-md object-cover object-center sm:h-48 sm:w-48"
                        />
                      )}
                      {service.service === "Gartenbewässerung" && (
                        <img
                          src={watering}
                          alt="watering "
                          className="h-24 w-24 rounded-md object-cover object-center sm:h-48 sm:w-48"
                        />
                      )}
                      {service.service === "Sonderlesitungen" && (
                        <img
                          src={GardenOtherService}
                          alt="GardenOtherService "
                          className="h-24 w-24 rounded-md object-cover object-center sm:h-48 sm:w-48"
                        />
                      )}
                      {service.service === "Abfallservice" && (
                        <img
                          src={garbage}
                          alt="garbage "
                          className="h-24 w-24 rounded-md object-cover object-center sm:h-48 sm:w-48"
                        />
                      )}
                      {service.service === "Handwerkerunterstützung" && (
                        <img
                          src={handwork}
                          alt="handwork "
                          className="h-24 w-24 rounded-md object-cover object-center sm:h-48 sm:w-48"
                        />
                      )}
                      {service.service === "House-sitting" && (
                        <img
                          src={housesitting}
                          alt="housesitting "
                          className="h-24 w-24 rounded-md object-cover object-center sm:h-48 sm:w-48"
                        />
                      )}
                      {service.service === "Haustierservice" && (
                        <img
                          src={pet}
                          alt="pet "
                          className="h-24 w-24 rounded-md object-cover object-center sm:h-48 sm:w-48"
                        />
                      )}
                      {service.service === "Einkaufsservice" && (
                        <img
                          src={shopping}
                          alt="shopping "
                          className="h-24 w-24 rounded-md object-cover object-center sm:h-48 sm:w-48"
                        />
                      )}
                    </div>
                    <div className="ml-4 flex  flex-row justify-between ">
                      <div className="ml-4 flex flex-1 flex-col justify-between sm:ml-6">
                        <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
                          <div>
                            <div className="flex justify-between mb-5">
                              <h3 className="text-xl font-bold">
                                {service?.service}
                              </h3>
                            </div>
                            <div className="mt-1 flex text-sm">
                              <p className="text-gray-500"> {service?.type}</p>
                            </div>

                            {service.service === "Sonderreinigungen" ||
                            service.service === "Sonderlesitungen" ? (
                              <p className="mt-1 text-sm font-medium text-gray-900">
                                {service?.specification1.substring(0, 50)}...
                              </p>
                            ) : (
                              <p className="mt-1 text-sm font-medium text-gray-900">
                                {service?.specification1}
                              </p>
                            )}

                            <p className="mt-1 text-sm font-medium text-gray-900">
                              {service?.specification2}
                            </p>
                            <p className="mt-1 text-sm font-medium text-gray-900">
                              {service?.specification3}
                            </p>
                            <p className="mt-1 text-sm font-medium text-gray-900">
                              {service?.specification4}
                            </p>
                            <p className="mt-1 text-sm font-medium text-gray-900">
                              {service?.specification5}
                            </p>
                            <p className="mt-1 text-sm font-medium text-gray-900">
                              {service?.specification6}
                            </p>
                          </div>

                          <div>
                            <div className=" font-bold flex flex-1 flex-col justify-between ">
                              <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
                                <div>
                                  <p className="mt-1 text-sm font-bold text-gray-900">
                                    {service?.date}
                                  </p>
                                  <p className="mt-1 text-sm font-bold text-gray-900">
                                    {service?.time}
                                  </p>
                                </div>
                              </div>
                              <div className="mt-10">
                                <button
                                  type="button"
                                  onClick={() => deleteItem(service.id)}
                                  className="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                                >
                                  Stornieren
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </section>

            {/* Order summary */}
            <section
              aria-labelledby="summary-heading"
              className="mt-16 rounded-lg bg-gray-50 px-4 py-6 sm:p-6 lg:col-span-5 lg:mt-0 lg:p-8"
            >
              <h2
                id="summary-heading"
                className="text-lg font-medium text-gray-900"
              >
                Anfrage zusammenfassung
              </h2>

              {services.map((service) => (
                <dl key={service.id} className="mt-6 space-y-4">
                  <div className="flex items-center justify-between">
                    <dt className="text-sm text-gray-600">
                      {service?.service}
                    </dt>
                    <dd className="text-sm font-medium text-gray-900">
                      {service?.date}
                    </dd>
                  </div>
                </dl>
              ))}

              <div className="mt-6">
                <button
                  onClick={requestSendHandler}
                  type="submit"
                  className="uppercase  bottom-3 shadow-lg shadow-[#b1c2a3] bg-gradient-to-r from-[#C0CDB5] via-[#a4b496] to-[#a4b496] hover:bg-gradient-to-br  active:bg-[#92a583]  md:align-middle   bg-[#C0CDB5] md:w-[400px] w-[200px] rounded-md font-medium my-6 md:mx-auto ml-10 py-3 text-black"
                >
                  Weiter
                </button>
              </div>
            </section>
          </form>
        )}
      </div>
  
    </div>
  );
};

export default ServiceCard;
