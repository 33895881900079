

import LivingroomcCleaningWithIntervallCard from "../components/ui/components/cards/LivingroomcCleaningWithIntervallCard";
import WindowCleaningCard from "../components/ui/components/cards/WindowCleaningCard";
import IroningCard from "../components/ui/components/cards/IroningCard";
import LaundryCard from "../components/ui/components/cards/LaundryCard";
import HousePlantServiceCard from "../components/ui/components/cards/HousePlantServiceCard";
import EveryOtherTypeOfCleaning from "../components/ui/components/cards/EveryOtherTypeOfCleaning";

const HouseCleaning = () => {
  return (
    <div className="w-full py-[3rem] flex gap-10 flex-col lg:items-center justify-items-stretch px-4 bg-white  ">
    <LivingroomcCleaningWithIntervallCard/>
    <WindowCleaningCard/>
    <LaundryCard/>
    <IroningCard/>

    <HousePlantServiceCard/>
    <EveryOtherTypeOfCleaning/>
    </div>
   
  );
};

export default HouseCleaning;
