import React from "react";
import Lottie from "lottie-react";
import animation from "../ressources/lotti/animationgdpr.json";
const AGB = () => {
  return (
    <div className="w-full 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4 bg-white mb-10">
      <div className="flex flex-col lg:flex-row justify-between gap-8 shadow-lg rounded-md px-5 pb-10">
        <div className="w-full flex justify-center lg:w-12/12  flex-col ">
          <div className="w-full flex justify-center lg:w-12/12 items-center flex-col ">
            <div className="w-[200px] h-[200px]">
              <Lottie animationData={animation} loop={false} />
            </div>
          </div>

          <h1 className="text-xl lg:text-2xl font-bold  text-gray-400 pb-10 mt-[20px]">
            1. Geltungsbereich
          </h1>
          <div className=" font text-xl lg:w-10/12 text-[#5A4B40] ">
            <p className=" text-justify">
              Die gegenständlichen Allgemeinen Geschäftsbedingungen der Afua
              GmbH. (in der Folge AFUA genannt) gelten für sämtliche
              Vertragsverhältnisse und Geschäftsbeziehungen, in denen AFUA
              Auftragsnehmer ist. Allgemeine Geschäftsbedingungen von
              Auftraggebern der AFUA werden von AFUA nicht angenommen und können
              daher bei Beauftragung von AFUA nie Vertragsinhalt werden. Werden
              einzelvertraglich zwischen AFUA und ihrem Auftraggeber von den
              Regelungen dieser Allgemeinen Geschäftsbedingungen abweichende
              Vereinbarungen getroffen, so müssen diese entweder bereits im
              schriftlichen Angebot der AFUA an den Auftraggeber enthalten sein,
              oder im Falle eines (Gegen)Angebots des Auftraggebers von AFUA
              ausdrücklich schriftlich angenommen werden. Mündliche Äußerungen
              seitens der Mitarbeiter/Geschäftsführer der AFUA sind stets
              unverbindlich.
            </p>
            <br />
            <h1 className="text-xl lg:text-2xl font-bold  text-gray-400 pb-10 mt-[20px]">
              2. Allgemeines zur Leistungserbringung
            </h1>
            <p className=" text-justify">
              Die AFUA-Dienstleistungspalette umfasst jeglichen Bereich der
              Hausservicedienstleistungen. Der Umfang der zu erbringenden
              Leistungen ergibt sich aus dem schriftlichen Vertrag, der zwischen
              AFUA und dem Auftraggeber abgeschlossen wurde bzw. aus dem
              schriftlichem Anbot der AFUA, das durch den Auftraggeber
              angenommen wurde.
            </p>
            <br />
            <p className=" text-justify">
              Die durch AFUA zu erbringenden Leistungen wurden auf Grundlage der
              Angaben und Informationen, die vom Auftraggeber erteilt wurden,
              gemeinsam mit dem Auftraggeber erarbeitet und festgelegt. Der
              Auftraggeber steht dafür ein, dass sämtliche von seiner Seite der
              AFUA zur Verfügung gestellten Angaben und Informationen richtig
              und vollständig sind. Insbesondere ist der Auftraggeber auch
              verpflichtet, während dieser Erarbeitung der durch AFUA zu
              erbringenden Leistungen AFUA darüber aufzuklären, wenn Teile des
              vertragsgegenständlichen Objektes oder der von der
              Leistungserbringung betroffenen Gegenstände einer speziellen
              Behandlung bedürfen. Kommt der Auftraggeber dieser Hinweispflicht
              nicht nach, ist eine Haftung und Gewährleistung von AFUA in diesem
              Zusammenhang ausgeschlossen.
            </p>
            <h1 className="text-md lg:text-xl font-bold  text-gray-400 pb-10 mt-[20px]">
              AFUA sorgt für
            </h1>
            <div>
              <ul className="list-disc ml-[80px]">
                <li>geschultes, gewerbliches Personal; </li>
                <li>
                  einheitliche Firmenbekleidung inkl. Namensschild und
                  Firmenlogo;{" "}
                </li>
                <li>
                  erforderliche Reinigungsmaschinen, - Geräte und Produkte;{" "}
                </li>
                <li>
                  Kontrollkräfte (Meister, Facharbeiter, Objektmanager,
                  Vorarbeiter).{" "}
                </li>
              </ul>
            </div>
            <h1 className="text-md lg:text-xl font-bold  text-gray-400 pb-10 mt-[20px]">
              Der Auftraggeber sorgt dafür,
            </h1>
            <div>
              <ul className="list-disc ml-[80px]">
                <li>
                  dass die Mitarbeiter von AFUA während der Leistungserbringung
                  über kaltes und heißes Wasser, sowie über Strom verfügen, dies
                  über eigene Kosten des Auftraggebers;{" "}
                </li>
                <li>
                  dass die Mitarbeiter von AFUA die vertragsgegenständlichen
                  Räumlichkeiten pünktlich und gefahrenlos zum vereinbarten
                  Arbeitsbeginn betreten können und mit der Leistungserbringung
                  beginnen können sowie die Leistungen ungehindert erbringen
                  können; widrigenfalls entfällt die Pflicht zur
                  Leistungserbringung seitens AFUA bei aufrechtem Preisanspruch.
                </li>
              </ul>
            </div>
            <h1 className="text-xl lg:text-2xl font-bold  text-gray-400 pb-10 mt-[20px]">
              3. Leistungsverzeichnis und Preis
            </h1>
            <p className=" text-justify">
              AFUA erstellt ihren Auftraggebern stets ein individuelles Angebot auf Grundlage der spezifischen Leistungsanforderungen des jeweiligen Auftraggebers. Der schriftliche Vertrag, der zwischen AFUA und dem Auftraggeber abgeschlossen wurde bzw. das schriftliche Anbot der AFUA, das durch den Auftraggeber angenommen wurde, enthält ein exaktes Leistungsverzeichnis sowie den daraus resultierenden, fairen AFUA-Preis.
            </p>
            <br />
            <p className=" text-justify">
              Sofern nicht einzelvertraglich etwas Gegenteiliges vereinbart wurde, wurde bei der Festlegung der Leistungsanforderungen eine übliche Nutzung des Vertragsobjektes und den damit verbundene Verschmutzungsgrad angenommen. Bei Auftreten von besonderem Verschmutzungsgrad (zB aufgrund von Gebäudeeinbrüchen, Bau- und Renovierungsarbeiten, nach besonderen Veranstaltungen im Vertragsobjekt, etc.) werden die Leistungen von AFUA gesondert verrechnet.
            </p>
            <br />
            <p>
              Die im Angebot genannten Preise gelten unter dem Vorbehalt, dass die zugrunde gelegten Auftragsdaten unverändert bleiben.
            </p>
            <br />
            <p className=" text-justify">
              Die vereinbarten Preise verstehen sich im Zweifel in Euro ohne gesetzliche Umsatzsteuer und basieren auf den kollektivvertraglichen Lohn- und Lohnnebenkosten im Zeitpunkt des Vertragsabschlusses bzw. der Angebotslegung durch AFUA. Anfallende Material-, Transport-, Fahrt- und Versicherungskosten sowie die Kosten für die Bereitstellung von Reinigungsgeräten wurden entsprechend dem vereinbarten Leistungsumfang festgelegt. AFUA ist berechtigt, bei allgemeinen Kostensteigerungen, die im Reinigungsgewerbe auftreten, die Preise anzupassen.
            </p>
            <br />
            <p>
              Zahlungen werden – soweit einzelvertraglich nichts Abweichendes vereinbart wurde – sofort nach Leistungserbringung ohne Skonto fällig. Bei Zahlungsverzug ist AFUA berechtigt, Mahn- und Inkassospesen dem Auftraggeber in Rechnung zu stellen. Erfolgt die Mahnung durch AFUA, ohne Einschaltung eines Rechtsanwaltes oder eines Inkassobüros, ist AFUA berechtigt, pro Mahnung EUR 13,50 Bearbeitungsgebühr dem Auftraggeber in Rechnung zu stellen. Bei Nichtzahlung einer offenen Rechnung binnen 30 Tagen, ist AFUA ohne weitere Verständigung des Auftraggebers berechtigt, laufende Arbeiten unverzüglich einzustellen.
            </p>
            <h1 className="text-xl lg:text-2xl font-bold  text-gray-400 pb-10 mt-[20px]">
              4. Vertragslaufzeit
            </h1>
            <p>
              Sofern im schriftlichen Vertrag, der zwischen AFUA und dem Auftraggeber abgeschlossen wurde bzw. im schriftlichen Anbot der AFUA, das durch den Auftraggeber angenommen wurde, nicht Abweichendes vereinbart ist, gilt Folgendes:
            </p>
            <br />
            <p className=" text-justify">
              Im Zweifel gelten Verträge auf unbestimmte Zeit abgeschlossen.
            </p>
            <br />
            <p className=" text-justify">
              Unbefristete Verträge können zu jedem Monatsletzten unter Einhaltung einer dreimonatigen Kündigungsfrist mittels eingeschriebenen Briefs von beiden Seiten gekündigt werden.
            </p>
            <br />
            <p className=" text-justify">


              Wird bei einem befristeten Vertrag nach Ablauf der Vertragslaufzeit das Vertragsverhältnis einvernehmlich fortgesetzt, so verlängert sich der Vertrag automatisch auf unbestimmte Dauer.
            </p>
            <br />
            <p className=" text-justify">
              Im Falle einer vorzeitigen Auflösung vermag sich der Auftraggeber erst dann auf eine Nicht- oder Schlechtleistung zu berufen, wenn mehrfach begründete und nachweislich schriftliche Beanstandungen (Reklamationen) nach Kenntnisnahme durch AFUA binnen angemessener Frist dennoch nicht behoben wurden. Für den Fall, dass der Auftraggeber Zahlungen nicht oder verspätet leistet, ist AFUA berechtigt, unter Setzung einer fünftägigen Nachfrist vom Vertrag mit sofortiger Wirkung zurückzutreten, ohne jedwede Leistungen mehr erbringen zu müssen, ohne dass dies zudem Verzugsfolgen nach sich zieht.
            </p>
            <br />
            <p className=" text-justify"> 
              Das Vertragsverhältnis kann außerdem ohne Einhaltung einer Frist durch schriftliche Erklärung aus wichtigem Grund aufgelöst werden, wenn über das Vermögen des jeweiligen Vertragspartners ein
              Insolvenzverfahren (Konkurs- oder Sanierungsverfahren) eröffnet oder ein Antrag auf Insolvenzeröffnung mangels kostendeckenden Vermögens abgewiesen wird;

            </p>
            <br />
            <p className=" text-justify">
              Der Auftraggeber ist verpflichtet, AFUA über Verkauf oder sonstige gänzliche Aufgabe des vertragsgegenständlichen Objektes sowie über eine Standortverlegung vorab schriftlich zu informieren. AFUA ist berechtigt – mit Ausnahme einer Rechtsnachfolge – bei Verkauf oder sonstiger gänzlicher Aufgabe des Objektes des Auftraggebers, den Vertrag unter Einhaltung einer Kündigungsfrist von einem Monat ab Verständigung vorzeitig zu beenden. Bei Standortverlegung hat AFUA ein Wahlrecht, ihre Leistungen am neuen Standort nach entsprechender Vertragsanpassung auf die neuen Gegebenheiten fortzusetzen, oder den Vertrag unter Einhaltung einer Kündigungsfrist von einem Monat ab Verständigung vorzeitig zu beenden. Unterlässt der Auftraggeber, AFUA über Verkauf oder sonstige gänzliche Aufgabe des vertragsgegenständlichen Objektes, oder über eine Standortverlegung vorab schriftlich zu informieren, so beginnt die Kündigungsfrist von einem Monat ab dem tatsächlichen Bekanntwerden dieser Umstände durch AFUA, frühestens jedoch mit dem Verkauf oder mit der sonstigen gänzlichen Aufgabe des vertragsgegenständlichen Objektes sowie mit erfolgter Standortverlegung.
            </p>
            <h1 className="text-xl lg:text-2xl font-bold  text-gray-400 pb-10 mt-[20px]">
              5. Lieferverzug
            </h1>
            <p className=" text-justify">
              Ist das Erbringen der vereinbarten Leistung aufgrund von Umständen, welche in der Sphäre des Auftraggebers liegen, nicht möglich, entbindet dies den Auftraggeber nicht von seiner vertraglichen Zahlungsverpflichtung.
            </p>
            <h1 className="text-xl lg:text-2xl font-bold  text-gray-400 pb-10 mt-[20px]">
              6. Gewährleistung und Haftung
            </h1>
            <p className=" text-justify">
              AFUA haftet für eine sach- und fachgerechte Leistung. AFUA haftet für alle Schäden, die bei der Leistungserbringung entstehen und die ihre Mitarbeiter vorsätzlich oder grob fahrlässig verursacht haben. Der Auftraggeber hat Schäden und Mängel binnen 7 Werktagen ab Leistungserbringung AFUA schriftlich anzuzeigen, widrigenfalls entfällt die Haftung von AFUA.
            </p>
            <br />
            <p>
              Die Haftung von AFUA ist der Höhe nach mit jenen Beträgen begrenzt, mit welchen die Haftpflichtversicherung im Schadensfall Deckung leistet. Die Haftung von AFUA für Sachschäden besteht nur für den Zeitwert zum Zeitpunkt des Schadensereignisses.
            </p>
            <br />
            <p className=" text-justify">
              Eine Haftung von AFUA für Folgeschäden, insbesondere für Ertrags- und Verdienstausfall, entgangenen Gewinn, Regressansprüche Dritter, Schäden aus Betriebsunterbrechung sowie daraus resultierende Schadensersatzansprüche, besteht nicht. Die Ursachen der Haftung sind vom Auftraggeber nachzuweisen.
            </p>
            <br />
            <p className=" text-justify">
              Der Auftraggeber ist verpflichtet, AFUA schriftlich zu informieren, wenn zu reinigende Objekte, oder die darin befindlichen Flächen und Gegenstände einer besonderen Behandlung bedürfen. Kommt der Auftraggeber dieser Hinweispflicht nicht nach, ist jede Haftung bzw. Gewährleistung von AFUA ausgeschlossen.
            </p>
            <br />
            <p>
              Die dem Personal des Auftragnehmers übergebenen Schlüssel können bei Verlust nur im Wert des Einzelschlüssels ersetzt werden.
            </p>
            <br />
            <p className=" text-justify">


              Sollte eine der Parteien aufgrund Höherer Gewalt nicht in der Lage sein, ihren vertraglichen Pflichten nachzukommen, ist diese Partei für die Dauer der Höheren Gewalt von ihren Pflichten entbunden. Als Höhere Gewalt werden insbesondere die COVID-19-Pandemie, Krieg, Elementarereignisse, Bürgerunruhen, Naturgewalten oder Feuer, Sabotage, Maßnahmen der Regierung, öffentliche Unruhen, Ausnahmezustand, Streiks, Aussperrungen, Terror, epidemische Krankheiten, Unwetter, Dachlawinen und andere Ereignisse, welche unkontrollierbar, unvermeidbar, mit zumutbaren Mitteln nicht zu bewältigen sind und nicht durch eine Partei verursacht wurden.



              AFUA ist berechtigt, in derartigen Fällen ihre Leistungen zu unterbrechen, einzuschränken oder entsprechend umzustellen.
            </p>
          </div>

          <div className="font-bold mt-10 text-xl">
            Sie erreichen uns unter folgenden Kontaktdaten:
          </div>
          <p className="text-black">Susanne Peterka</p>
          <p className="text-black">c/o AFUA Real Estate GmbH</p>
          <p className="text-black">Georg Sigl-Straße 40-42</p>
          <p className="text-black">2384 Breitenfurt</p>
          <a
            href="mailto:service@diehausmaennerinnen.at"
            className=" w-[250px] text-black
          border-b border-b-gray-400 hover:text-blue-400 hover:border-b-blue-400"
          >
            service@diehausmaennerinnen.at
          </a>
          {/* <p className=" font text-xl lg:w-10/12 text-[#5A4B40]">
          Sie erreichen uns unter folgenden Kontaktdaten:
            <span className=" font-bold text-xl lg:w-10/12 text-[#5A4B40] ml-2">0660 / 6 200 600</span>
          </p>
          <p className=" font text-xl lg:w-10/12 text-[#5A4B40] mb-[80px]  ">
          oder schreiben Sie ein Mail:
          <a href="mailto:service@diehausmaennerinnen.at" className=" font-bold text-xl lg:w-10/12 text-[#5A4B40] ml-2
          border-b border-b-gray-400 hover:text-blue-400 hover:border-b-blue-400">service@diehausmaennerinnen.at</a>
           
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default AGB;
