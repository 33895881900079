import React from "react";
import image from "../../../../ressources/images/othertypeofcleaning.jpg";
import IroningRequest from "../Dialogs/IroningRequest";
import EveryOtherTypeOfCleaningRequest from "../Dialogs/EveryOtherTypeOfCleaningRequest";

const EveryOtherTypeOfCleaning = () => {
  const [open, setOpen] = React.useState(false);
  function modalHandler(e:any){
    setOpen(!open)
  

  }
  function cloneModal(){
  
    setOpen(false)
  
  }
  return (
    <div onClick={modalHandler}  className="flex flex-col rounded-lg  hover:scale-105 hover:bg-[#f1f2f6] cursor-pointer duration-300  bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 md:min-w-[80%] md:max-w-[80%]  md:flex-row ">
      <img
        className="h-96 w-full rounded-t-lg object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
        src={image}
        alt="LaundryImg"
      />
      <div className="flex flex-col justify-center p-6 md:w-[60%]">
        <h5 className="mb-2 text-xl font-medium text-neutral-800 dark:text-neutral-50">
        Sonderreinigungen
        </h5>
        <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200 mt-5">
        Sie haben besondere Wünsche und wollen zum Beispiel eine Teppich-,
         Polster- oder Vorhangreinigung - Bitte beschreiben Sie Ihren Wunsch.
        </p>
       
      </div>
      <div className="flex   justify-start  my-15 md:ml-15 md:mt-[20px]">
        <EveryOtherTypeOfCleaningRequest openDialog={open} onModalHandler={cloneModal}/>
      </div>
    </div>

  );
};

export default EveryOtherTypeOfCleaning;
