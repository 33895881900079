import React from "react";
import { CircularProgress, TextField } from "@mui/material";
import cleaningImg from "../../../ressources/images/cleaningservicet.png";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import { auth } from "../../../firebase.config";
import { useFormik } from "formik";
import { signupSchema } from "../../../schema";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useAtom } from 'jotai';
import { authUser } from '../../../stores/authAtom';
const SignUpWithEmail = () => {
  const toast = useToast();
  const navigation = useNavigate();
  const [user,setUser]=useAtom(authUser);
  const auth = getAuth();
const [loading,setLoading]=React.useState(false);
  const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: signupSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const SignUpHandler = async () => {
    setLoading(true);
    createUserWithEmailAndPassword(auth, values.email, values.password)
  
    .then((userCredential) => {
      // Signed in 
      const u = userCredential;
      setLoading(false);
      setUser(u.user)
      navigation('/dashboard')
      // ...
    })
    .catch((error) => {
      setLoading(false);
      const errorCode = error.code;
      const errorMessage = error.message;
      errorToastHandler(errorMessage);
      // ..
    });
    // if (error) {
      
    // } else {
    //   successToastHandler();
    //   navigation("/dashboard");
    // }
  };

  const errorToastHandler = (message:string) => {
    toast({
      title: "Fehler",
      description: message,
      status: "error",
      duration: 9000,
      isClosable: true,
      position: "top-right",
    });
  };

  const successToastHandler = () => {
    toast({
      title: "Registrierung erfolgreich",
      description: null,
      status: "success",
      duration: 9000,
      isClosable: true,
      position: "top-right",
    });
  };
  return (
    <>
      <section
        className="bg-[#f7f7f7] min-h-screen flex items-center
justify-center"
      >
        <div
          className="bg-[#f7f8f7] flex rounded-2xl shadow-lg
        max-w-3xl p-5"
        >
          <div className="sm:w-1/2 px-16 ">
            <h2 className="font-bold text-2xl text-[#C0CDB5] mb-10">
              Registrieren
            </h2>

            <form onSubmit={handleSubmit}>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Ihr Email
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 16"
                  >
                    <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
                    <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
                  </svg>
                </div>
                <div></div>
                <input
                  type="email"
                  id="email"
                  value={values.email}
                  onChange={handleChange}
                  className={
                    errors.email
                      ? "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full pl-10 p-2.5 "
                      : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 "
                  }
                  placeholder="max@mustermann.at"
                />
              </div>

              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium mt-3 text-gray-900 dark:text-white"
              >
                Password
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 448 512"
                  >
                    <path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z" />
                  </svg>
                </div>
                <input
                  type="password"
                  id="password"
                  value={values.password}
                  onChange={handleChange}
                  className={
                    errors.password
                      ? "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full pl-10 p-2.5 "
                      : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 "
                  }
                  placeholder="******"
                />
              </div>

              <label
                htmlFor="passwordConfirmation"
                className="block mb-2 text-sm font-medium mt-3 text-gray-900 dark:text-white"
              >
                Password überprüfen
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 448 512"
                  >
                    <path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z" />
                  </svg>
                </div>
                <input
                  type="password"
                  id="passwordConfirmation"
                  value={values.passwordConfirmation}
                  onChange={handleChange}
                  className={
                    errors.passwordConfirmation
                      ? "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full pl-10 p-2.5 "
                      : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 "
                  }
                  placeholder="******"
                />
              </div>

              <button
                className="uppercase bg-[#C0CDB5] w-[240px] mt-10
                hover:bg-[#b1c2a3] active:bg-[#92a583]
               rounded-md font-medium my-3 mx-auto py-2 text-[#5A4B40]"
                onClick={SignUpHandler}
              >
                Registrieren
              </button>
              {loading && <CircularProgress color="success" />}
            </form>

            <div
              className="mt-10 grid grid-cols-3 items-center
             text-gray-400"
            ></div>
          </div>
          <div className="w-1/2">
            <img
              className="sm:block hidden rounded-2xl"
              src={cleaningImg}
              alt="#"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default SignUpWithEmail;
