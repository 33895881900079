import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useAtom } from "jotai";
import serviceCardAtom from "../../../../stores/serviceCardAtom";
import uuid from 'react-uuid';
import { useToast } from '@chakra-ui/react'
import { today, formatDate} from "../../../../helpers/timehelper";
import { useFormik } from 'formik';
import { laundrySchema } from '../../../../schema';
import Lottie from "lottie-react";
import animation from '../../../../ressources/lotti/animation_laundry.json'
import { json } from 'stream/consumers';
const LaundryForm=React.forwardRef<any,{}>((props, ref)=>{
  const [service, setService] = useAtom(serviceCardAtom);

  const toast = useToast();
  const deactivateForm = (e: any) => {
    e.preventDefault();
  };
  const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: {
      numberOfWash:"1",
      interval:"Nein",
      date:"",
      time:"",
      time2:""
    },
    validationSchema: laundrySchema,
    onSubmit: deactivateForm,
  });
  const addToCardHandler = () => {
    addToServices();
  };
const addToServices=()=>{
  setService([...service, {id:uuid(),type:'Haus',
  service:'Wäscheservice',
  specification1:'Anzahl Waschmaschinenladungen: '+values.numberOfWash,
  specification2:null,
  specification3:null,
  specification4:null,
  specification5:'Intervall: ' + values.interval,
  specification6:null,
  date:formatDate(values.date),
  time:values.time + ' bis ' +values.time2
  }])
  toast({
    title: 'Wäscheservice',
    description: "Wurde im Warenkorb hinzugefügt",
    status: 'success',
    duration: 2000,
    isClosable: true,
    position:'top-right'
  })

}
  React.useImperativeHandle(ref, () => ({
    addToCardHandler,
  }));
return(
  <section className="bg-white dark:bg-gray-900 w-[100%]">
  <div className=" px-4 py-8 w-[100%] ">
      
      <form onSubmit={deactivateForm}>
          <div className="grid gap-4 mb-4 sm:grid-cols-2 sm:gap-6 sm:mb-5  w-[100%]">
              <div className="sm:col-span-2">
                  <label htmlFor="numberOfWash" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Anzahl Waschmaschinenladungen</label>
                  <input type="number"  id="numberOfWash" 
                    value={values.numberOfWash}
                 onChange={handleChange}
              
                    min={"1"}
                 
                   className={
                    errors.numberOfWash
                      ? "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-600 focus:border-red-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600    dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500   dark:focus:border-red-500"
                      : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600  focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500  dark:focus:border-primary-500"
                  }
                />
                {errors.numberOfWash && (
                  <p className="text-red-500 text-xs mt-1">
                     Die Anzahl der Waschmaschinenladungen darf nicht negativ sein!
                  </p>
                )} 
              </div>
              <div className="sm:col-span-2">
          <label
              htmlFor="date"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
             Wunschtermin
            </label>
            <input
              type="date"
              name="date"
              id="date"
              value={values.date}
              onChange={handleChange}
              className={
                errors.date
                  ? "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-600 focus:border-red-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                  : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              }
            />
            {errors.date && (
              <p className="text-red-500 text-xs mt-1">
                Bitte wählen sie einen Wunschtermin
              </p>
            )}
          </div>
          <div  >
            <label
              htmlFor="time"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
             Zeit von
            </label>
            <select
              id="time"
              value={values.time}
              onChange={handleChange}
              className={
                errors.time
                  ? "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                  : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              }            >
           <option defaultValue="6:00">6:00</option>
              <option value="6:30">6:30</option>
              <option value="7:00">7:00</option>
              <option value="7:30">7:30</option>
              <option value="8:00">8:00</option>
              <option value="8:30">8:30</option>
              <option value="9:00">9:00</option>
              <option value="9:30">9:30</option>
              <option value="10:00">10:00</option>
              <option value="10:30">10:30</option>
              <option value="11:00">11:00</option>
              <option value="11:30">11:30</option>
              <option value="12:00">12:00</option>
              <option value="12:30">12:30</option>
              <option value="13:00">13:00</option>
              <option value="13:30">13:30</option>
              <option value="14:00">14:00</option>
              <option value="14:30">14:30</option>
              <option value="15:00">15:00</option>
              <option value="15:30">15:30</option>
              <option value="16:00">16:00</option>
              <option value="16:30">16:30</option>
              <option value="17:00">17:00</option>
              <option value="17:30">17:30</option>
              <option value="18:00">18:00</option>
              <option value="18:30">18:30</option>
              <option value="19:00">19:00</option>
              <option value="19:30">19:30</option>
              <option value="20:00">20:00</option>
              <option value="20:30">20:30</option>
              <option value="21:00">21:00</option>
            </select>
            {errors.time && (
                <p className="text-red-500 text-xs mt-1">
                  Bitte wählen sie ein Wunschzeitfenster
                </p>
              )}
          </div>
          <div  >
            <label
              htmlFor="time2"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
             bis
            </label>
            <select
              id="time2"
              value={values.time2}
              onChange={handleChange}
              className={
                errors.time2
                  ? "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                  : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              }            >
                <option defaultValue="6:00">6:00</option>
              <option value="6:30">6:30</option>
              <option value="7:00">7:00</option>
              <option value="7:30">7:30</option>
              <option value="8:00">8:00</option>
              <option value="8:30">8:30</option>
              <option value="9:00">9:00</option>
              <option value="9:30">9:30</option>
              <option value="10:00">10:00</option>
              <option value="10:30">10:30</option>
              <option value="11:00">11:00</option>
              <option value="11:30">11:30</option>
              <option value="12:00">12:00</option>
              <option value="12:30">12:30</option>
              <option value="13:00">13:00</option>
              <option value="13:30">13:30</option>
              <option value="14:00">14:00</option>
              <option value="14:30">14:30</option>
              <option value="15:00">15:00</option>
              <option value="15:30">15:30</option>
              <option value="16:00">16:00</option>
              <option value="16:30">16:30</option>
              <option value="17:00">17:00</option>
              <option value="17:30">17:30</option>
              <option value="18:00">18:00</option>
              <option value="18:30">18:30</option>
              <option value="19:00">19:00</option>
              <option value="19:30">19:30</option>
              <option value="20:00">20:00</option>
              <option value="20:30">20:30</option>
              <option value="21:00">21:00</option>
            </select>
            {errors.time2 && (
              <p className="text-red-500 text-xs mt-1">
                Bitte wählen sie ein Wunschzeitfenster
              </p>
            )}
          </div>
          <div  className="sm:col-span-2">
            <label
              htmlFor="interval"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Intervall
            </label>
            <select
              id="interval"
              value={values.interval}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
            >
              
              <option defaultValue="Nein">Nein</option>
              <option value="Wöchenlich">Wöchenlich</option>
              <option value="Zwei mal Wöchenlich">Zwei mal Wöchenlich</option>
              <option value="Alle zwei Wochen">Alle zwei Wochen</option>                     
              <option value="Monatlich">Monatlich</option>
            </select>
          </div>
          </div>
         
      </form>
  </div>
</section>
)
})
const LaundryRequest = (props:any) => {


    const [open, setOpen] = React.useState(false);
   
   
 
    const theme = useTheme();

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
 
    const handleClickOpen = () => {
        setOpen(true);
      };


  const handleClose = () => {
    
    props.onModalHandler()
  };
  const handleDialogClick = (event:any) => {

    event.stopPropagation();
  };

  const childRef = React.useRef<any | null>(null);
  const addServiceHanler = () => {
    props.onModalHandler()
  
    handleTriggerChildMethod();
              
     
    setOpen(false);
  };
  const handleTriggerChildMethod = () => {
    childRef.current?.addToCardHandler();
  };
  return (
    <div>
           <button
        onClick={handleClickOpen}
        className="uppercase  bottom-3 shadow-lg shadow-[#b1c2a3] bg-gradient-to-r from-[#C0CDB5] via-[#a4b496] to-[#a4b496] hover:bg-gradient-to-br  active:bg-[#92a583]  md:align-middle   bg-[#C0CDB5] w-[200px] rounded-md font-medium my-6 md:mx-auto ml-10 py-3 text-black"
      >
      In den Warenkorb
      </button>
    
    <Dialog
        fullScreen={fullScreen}
        open={props.openDialog}
       
        aria-labelledby="responsive-dialog-title"
        fullWidth={true}
        onClick={handleDialogClick}
      >
     
        <DialogTitle id="responsive-dialog-title" sx={{backgroundColor:'#f1f2f6'}}>
        <div className="w-full bg-[#f1f2f6]">
        <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">
        Wäscheservice
          </h2>
        </div>
         
          <div className="w-full flex justify-center">
          <div className="w-[200px] h-[200px]">
              <Lottie animationData={animation} loop={true} />
            </div>
          </div>
         
        </DialogTitle>
        <DialogContent>
     <LaundryForm  ref={childRef}/>
        </DialogContent>
        <DialogActions>
          <Button color="error" autoFocus onClick={handleClose}>
          Beenden
          </Button>
          <Button onClick={addServiceHanler} autoFocus>
          Hinzufügen
          </Button>
        </DialogActions>
      </Dialog>
      </div>
  )
}

export default LaundryRequest