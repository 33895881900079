import React, { useEffect, useState } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Link ,useNavigate} from "react-router-dom";
 
import { authUser } from "../../stores/authAtom";
import serviceCardAtom, { customerAtom, customerInfoExists,phoneLogedIn } from "../../stores/serviceCardAtom";
import { useAtom } from "jotai";
import { useSignOut } from "react-firebase-hooks/auth";
import { auth } from "../../firebase.config";
import logo from "../../ressources/images/logo.png";
import text from "../../assets/text.json"
import { Badge } from "@mui/material";
const Navbar = () => {
  const [nav, setNav] = useState(false);
 
  const [services, setService] = useAtom(serviceCardAtom);
  const [number, setNumber] = useState(0);

const navigate=useNavigate();
  React.useEffect(()=>{

    setNumber(services.length)
  },[services])
let vavbarText=text.de.navbar
  const navHandler = () => {
    setNav(!nav);
  };

  const [user, setUser] = useAtom(authUser);
  const [signOut, loading, error] = useSignOut(auth);
  const NavItem: React.FC<{ name: string; linkTo?: string }> = ({
    name,
    linkTo,
  }) => {
    const [entered, setEntered] = useState(false);

    return (
      <div
        className="flex flex-col justify-center items-center  "
        onMouseEnter={() => setEntered(true)}
        onMouseLeave={() => setEntered(false)}
      >
        <>
          <li className="p-4 border-transparent flex  relative hover:-translate-y-1  hover:ease-linear duration-[.2s]">
            {linkTo ? (
              <>
                <Link to={linkTo}>{name}</Link>
              </>
            ) : (
              <>{name}</>
            )}
          </li>
          <div
            className="border-b border-gray-400  text-6xl font-bold "
            style={{
              width: entered ? `${name?.length - 2.1}rem` : 0,
              transition: "width .5s",
              fontSize: "60px",
            }}
          />
        </>
      </div>
    );
  };

  const Btn: React.FC<{ name: string}> = ({
    name,
    
  }) => {
    const [entered, setEntered] = useState(false);

    return (
      <div
        className="flex flex-col justify-center items-center"
        onMouseEnter={() => setEntered(true)}
        onMouseLeave={() => setEntered(false)}
      >
        <>
          <li className="p-4 border-transparent flex  relative hover:-translate-y-1  hover:ease-linear duration-[.2s]">
          <button
            onClick={async () => {
              signOut();
          
              setUser(undefined);
            }}
          >
            {name}
          </button>
          </li>
          <div
            className="border-b border-gray-400  text-6xl font-bold "
            style={{
              width: entered ? `${name?.length - 2.1}rem` : 0,
              transition: "width .5s",
              fontSize: "60px",
            }}
          />
        </>
      </div>
    );
  };

  return (
    <div className="flex  justify-between items-center h-26 max-w-[1440px] mx-auto  text-[#011627]  ">
      <div
        className={
          nav
            ? "w-full"
            : "w-full"
        }
      >
        <Link to={"/"}>
          <img src={logo} className="w-[200px] mt-1" />
        </Link>
      </div>

      <ul className="hidden md:flex w-[100%] text-xl font-bold">
        <NavItem name={vavbarText.home} linkTo="./" />
        <NavItem name={vavbarText.service} linkTo="./services" />
        <NavItem name={vavbarText.contact} linkTo="./contactus" />

        <NavItem name={vavbarText.about} linkTo="./aboutus" />
{/* 
        {user ? (
         <Btn name={vavbarText.signout}></Btn>
        ) : (
          <NavItem name={vavbarText.signin} linkTo="/signinmethods" />
        )} */}
        <Badge badgeContent={number} color="success" overlap="circular">
        <NavItem  name={vavbarText.shoppingcart} linkTo="/servicecard" />
        
</Badge>
    

        
      </ul>
      <ul className="hidden md:flex">
      <button
      onClick={()=>{
        navigate("/jobapplication")
      }}
       className='uppercase text-white bg-gradient-to-br from-[#c0ceb5] to-[#a7b998] hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 w-[200px] rounded-md font-medium  mx-auto py-3 '>
  MITARBEITER WERDEN
</button>
      </ul>
{/* <ul className="hidden md:flex  text-xl font-bold">
{user && (
           <NavItem name="dashboard" linkTo="/dashboard" />
        ) }
</ul> */}
      <div onClick={navHandler} className="block md:hidden cursor-pointer mr-3">
        {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>

      <ul onClick={()=>{  setNav(!nav);}}
        className={
          nav
            ? "fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-300 bg-[#f1f2f6] uppercase ease-in-out duration-500 z-[100] lg:hidden"
            : "ease-in-out duration-500 fixed left-[-100%] uppercase z-[100]"
        }
      >
        <div className="w-full flex justify-center mt-4 ">
          <Link to={"/"}>
            <img src={logo} className="w-[200px] mt-1" />
          </Link>
        </div>
        <li className="p-4 hover:border-r-2 hover:border-r-[#C0CDB5] relative hover:-translate-y-1  hover:ease-linear duration-[.2s]">
          <Link to={"/"}>{vavbarText.home}</Link>
        </li>
        <li className="p-4  hover:border-r-2 hover:border-r-[#C0CDB5] relative hover:-translate-y-1  hover:ease-linear duration-[.2s]">
          <Link to={"/services"}> {vavbarText.service}</Link>
        </li>
        <li className="p-4 hover:border-r-2 hover:border-r-[#C0CDB5] relative hover:-translate-y-1  hover:ease-linear duration-[.2s]">
          <Link to="./contactus">{vavbarText.contact}</Link>
        </li>
        <li className="p-4 hover:border-r-2 hover:border-r-[#C0CDB5] relative hover:-translate-y-1  hover:ease-linear duration-[.2s]">
          <Link to={"/aboutus"}> {vavbarText.about}</Link>
        </li>
      

        <li className="p-4 hover:border-r-2 hover:border-r-[#C0CDB5] relative hover:-translate-y-1  hover:ease-linear duration-[.2s]">
        <Badge badgeContent={services.length} color="success" anchorOrigin={{
    vertical: 'top',
    horizontal: 'right',
  }}>
        <Link to={"/servicecard"}> {vavbarText.shoppingcart}</Link>
</Badge>

        </li>
        <li className=" mt-5 translate-x-4">
        <button
      onClick={()=>{
        navigate("/jobapplication")
      }}
       className='uppercase text-white bg-gradient-to-br from-[#c0ceb5] to-[#a7b998] hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 w-[200px] rounded-md font-medium   py-3 '>
  MITARBEITER WERDEN
</button>
        </li>
        {/* {user && 
         <li className="p-4 hover:border-r-2 hover:border-r-[#C0CDB5] relative hover:-translate-y-1  hover:ease-linear duration-[.2s]">
         <Link to={"/dashboard"}> dashboard</Link>
       </li>
        } */}
     
      </ul>
  
    </div>
  );
};

export default Navbar;
