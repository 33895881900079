import React from "react";
import Lottie from "lottie-react";
import animation from "../ressources/lotti/animationgdpr.json";
const GDPR = () => {
  return (
    <div className="w-full 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4 bg-white mb-10">
      <div className="flex flex-col lg:flex-row justify-between gap-8 shadow-lg rounded-md px-5 pb-10">
        <div className="w-full flex justify-center lg:w-12/12  flex-col ">
          <div className="w-full flex justify-center lg:w-12/12 items-center flex-col">
            <div className="w-[200px] h-[200px]">
              <Lottie animationData={animation} loop={false} />
            </div>
          </div>

          <h1 className="text-xl lg:text-2xl font-bold  text-gray-400 pb-10 mt-[20px]">
            DSGVO
          </h1>
          <div className=" font text-xl lg:w-10/12 text-[#5A4B40]">
            <p className=" text-justify">
              Im Rahmen der Geschäftsanbahnung und des anschließenden
              Vertragsverhältnisses verarbeitet AFUA personenbezogene Daten
              ihrer Auftraggeber. Dabei handelt es sich insbesondere um jene
              Informationen, die von den Auftraggebern selbst zur Verfügung
              gestellt werden. Andere Informationen werden automatisch
              generiert, indem die Auftraggeber über die Systeme von AFUA
              kommunizieren; zB durch Versenden von E-Mails oder wenn sich
              Personen im Bereich von Überwachungskameras aufhalten. Auch können
              personenbezogene Daten eines Auftraggebers durch Dritte generiert
              bzw. zur Verfügung gestellt werden; etwa durch andere
              Auftraggeber, Auftragnehmer oder Mitarbeiter im Rahmen von
              Berichten, Beurteilungen, internen Untersuchungen oder
              Geschäftskorrespondenz.
            </p>
            <br />
            <p className=" text-justify">
              In der Regel benötigt AFUA die abgefragten Informationen von
              Auftraggebern zur Erfüllung eines Vertrages, zur Wahrung
              berechtigter Geschäftsinteressen bzw. zur Einhaltung rechtlicher
              Verpflichtungen. In diesen Fällen sind Auftraggeber im Rahmen
              ihrer Vertragspflichten angehalten, die Informationen zur
              Verfügung zu stellen. Eine Verweigerung dieser Daten kann die
              Vertragserfüllung durch AFUA unmöglich machen. Sollten die
              abgefragten Informationen nicht zur Erfüllung eines Vertrages bzw.
              zur Einhaltung rechtlicher Verpflichtungen erforderlich sein,
              werden die Auftraggeber darüber informiert, dass die
              Informationserteilung auf freiwilliger Basis erfolgt und daher
              auch verweigert werden kann.
            </p>
            <br />
            <p className=" text-justify">
              Auftraggeberdaten werden von AFUA im Einklang mit den anwendbaren
              Datenschutzgesetzen zum Zwecke berechtigter Geschäftsinteressen,
              insbesondere zur Auftragsverwaltung (Begründung, Durchführung,
              Ausgestaltung und Beendigung von Vertragsverhältnissen),
              Geltendmachung von vertraglichen Ansprüchen, sowie zur Erfüllung
              von gesetzlichen und arbeitsvertraglichen Verpflichtungen,
              einschließlich Aufzeichnungs-, Auskunfts- und Meldepflichten,
              verarbeitet. Im Rahmen dieser Zwecke werden Auftraggeberdaten von
              AFUA grundsätzlich nur gemäß DSGVO Art 6 zur Erfüllung von
              vertraglichen Pflichten, zur Erfüllung von rechtlichen
              Verpflichtungen oder zur Wahrung von berechtigten
              Geschäftsinteressen des Unternehmens oder eines Dritten
              verarbeitet.
            </p>
            <br />
            <p className=" text-justify">
              Im Zuge des gewöhnlichen Geschäftsbetriebs, zur Ausübung oder
              Verteidigung von Rechtsansprüchen und im Rahmen der berechtigten
              Interessen von AFUA kann erforderlich sein, dass Auftraggeberdaten
              an Auftragnehmer bzw. Rechtsanwälte übermittelt werden. Dabei
              werden Auftraggeberdaten nur insoweit übermittelt, als dies zur
              Zweckerreichung unbedingt erforderlich ist. Über dieses notwendige
              Maß hinaus werden keine Auftraggeberdaten offengelegt. In
              Erfüllung von gesetzlichen Verpflichtungen übermittelt AFUA auch
              personenbezogene Daten von Auftraggebern an öffentliche Stellen
              (zB Sozialversicherungsträger oder Abgabenbehörden). Über die
              gesetzlichen Verpflichtungen hinaus werden keine Auftraggeberdaten
              an öffentliche Stellen offengelegt. Sofern davon auch
              Mitarbeiterdaten des Auftraggebers umfasst sind (zB als
              Schlüsselarbeitskräfte), hat der Auftraggeber für eine
              ausreichende Rechtsgrundlage zu sorgen, wenn er AFUA solche Daten
              offenlegt. Zudem erhalten von AFUA beauftragte Auftragsverarbeiter
              (insbesondere IT- sowie Backoffice-Dienstleister)
              Auftraggeberdaten, sofern diese die Daten zur Erfüllung ihrer
              jeweiligen Leistung benötigen. Sämtliche Auftragsverarbeiter sind
              vertraglich entsprechend dazu verpflichtet, die übermittelten
              Daten vertraulich zu behandeln und nur im Rahmen der
              Leistungserbringung zu verarbeiten. Eine Übermittlung von
              Auftraggeberdaten an Empfänger in Drittländern findet nicht statt.
              Sollte in Ausnahmefällen dennoch eine derartige Übermittlung
              notwendig werden, erfolgt eine gesonderte Information an den
              betroffenen Auftraggeber.
            </p>
            <br />
            <p className=" text-justify">
              Es besteht die Möglichkeit, per Formular auf der Website von AFUA
              oder per E-Mail in Kontakt mit AFUA zu treten. In diesen Fällen
              werden die angegebenen Daten zwecks Bearbeitung der Anfrage und
              für den Fall von Anschlussfragen sechs Monate bei AFUA
              gespeichert.
            </p>
            <br />
            <p className=" text-justify">
              AFUA verarbeitet Auftraggeberdaten, für die Dauer des gesamten
              Vertragsverhältnisses, sowie darüber hinaus gemäß den gesetzlichen
              Aufbewahrungs- und Dokumentationspflichten, die sich u.a. aus dem
              Unternehmensgesetzbuch (UGB), der Bundesabgabenordnung (BAO) sowie
              verschiedenen arbeitsrechtlichen Sondergesetzen (zB GlbG, AVRAG,
              UrlG, ASchG, AÜG) ergeben. Nähere Informationen über die
              Verarbeitung der personenbezogenen Daten können Auftraggeber
              jederzeit auch unter auf der Website von AFUA sowie per E-Mail
              unter service@diehausmaennerinnen einholen.
            </p>
          </div>

          <div className="font-bold mt-10 text-xl">
            Sie erreichen uns unter folgenden Kontaktdaten:
          </div>
          <p className="text-black">Susanne Peterka</p>
          <p className="text-black">c/o AFUA Real Estate GmbH</p>
          <p className="text-black">Georg Sigl-Straße 40-42</p>
          <p className="text-black">2384 Breitenfurt</p>
          <a
            href="mailto:service@diehausmaennerinnen.at"
            className=" w-[250px] text-black
          border-b border-b-gray-400 hover:text-blue-400 hover:border-b-blue-400"
          >
            service@diehausmaennerinnen.at
          </a>
          {/* <p className=" font text-xl lg:w-10/12 text-[#5A4B40]">
          Sie erreichen uns unter folgenden Kontaktdaten:
            <span className=" font-bold text-xl lg:w-10/12 text-[#5A4B40] ml-2">0660 / 6 200 600</span>
          </p>
          <p className=" font text-xl lg:w-10/12 text-[#5A4B40] mb-[80px]  ">
          oder schreiben Sie ein Mail:
          <a href="mailto:service@diehausmaennerinnen.at" className=" font-bold text-xl lg:w-10/12 text-[#5A4B40] ml-2
          border-b border-b-gray-400 hover:text-blue-400 hover:border-b-blue-400">service@diehausmaennerinnen.at</a>
           
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default GDPR;
