import React from 'react';
import SignUp from './layouts/registeration/SignUp';
import { RouterProvider,createBrowserRouter } from 'react-router-dom';
import HomePage from './pages/HomePage';
import LottiePage from './pages/LottiePage';
import SignUpWithEmail from './components/ui/Forms/SignUpWithEmail';
import SignUpMethods from './components/ui/Forms/SignUpmethods';
import UserInfo from './pages/UserInfo';
import HouseCleaning from './pages/HouseCleaning';
import ServiceCard from './pages/ServiceCard';
import Services from './pages/Services';
import MainLayout from './layouts/MainLayout';
import RequireAuthLayout from './layouts/RequireAuthLayout';
import UserDashboard from './pages/UserDashboard';
import SigninWithEmail from './components/ui/Forms/SigninWithEmail';
import GardeningPages from './pages/GardeningPages';
import HelpingHands from './pages/HelpingHands';
import SigninWithLink from './components/ui/Forms/SigninWithLink';
import KontaktUs from './pages/KontaktUs';
import About from './pages/About';
import SiginwithPhoneNumber from './components/ui/Forms/SiginwithPhoneNumber';
import AnonymForm from './components/ui/Forms/AnonymForm';
import GDPR from './pages/GDPR';
import AGB from './pages/AGB';
import JobApplication from './components/ui/Forms/JobApplication';


const routs =createBrowserRouter([
  {
    path:'/',
    element:<MainLayout/>,
    children:[
      {
        path:'/',
        element:<HomePage/>
      },
      {
        path:'/signup',
      element:<SignUp/>
        //  element:<LottiePage/>
      },
      {
        path:'/signinwithemail',
   element:<SigninWithEmail/>
     
      //   element:<LottiePage/>
      },
     
      {
        path:'/signinasguess',
   
    
     element:<SigninWithLink/>
      //   element:<LottiePage/>
      },
     
     
      {
        path:'/signinwithphone',
   
      element:<SiginwithPhoneNumber/>
    
      //   element:<LottiePage/>
      },
      {
        path:'/signinmethods',
      element:<SignUpMethods/>
       //  element:<LottiePage/>
      },
      {
        path:'/housecleaning',
      element:<HouseCleaning/>
       //  element:<LottiePage/>
      },
      {
        path:'/servicecard',
        element:<ServiceCard/>
      // element:<LottiePage/>
      },
      {
        path:'/services',
        element:<Services/>
      }
      ,
      {
        path:'/signupeithemailandpassword',
      element:<SignUpWithEmail/>
       // element:<LottiePage/>
     
      } ,
      {
        path:'/gardeningrequest',
      element:<GardeningPages/>
    //  element:<LottiePage/>
     
      }
      ,
      {
        path:'/helpinghands',
      element:<HelpingHands/>
      //element:<LottiePage/>
     
      },{
        path:'/contactus',
        element:<KontaktUs/>
      }
      ,{
        path:'/aboutus',
        element:<About/>
      },{
        path:'/userinfo',
        element:<AnonymForm/>
      },{
        path:'/dgpr',
        element:<GDPR/>
      }
      ,{
        path:'/agb',
        element:<AGB/>
      }
      ,{
        path:'/jobapplication',
        element:<JobApplication/>
      }
    ]},{
      path:'/',
      element:<RequireAuthLayout/>,
      children:[
{
  path:'/userinfo',
  //element:<RequestPage/>
  element:<LottiePage/>
},


{
  path:'/dashboard',
element:<UserDashboard/>
 //  element:<LottiePage/>
},
      ]
    }
  ,])


function App() {
  return (
    <>


  <RouterProvider router={routs}/>


    </>
  );
}

export default App;
