import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useAtom } from "jotai";
import serviceCardAtom from "../../../../stores/serviceCardAtom";
import uuid from 'react-uuid';
import { today,formatDate} from "../../../../helpers/timehelper";
import { useToast } from '@chakra-ui/react'
import { useFormik } from "formik";
import { roomCleaningSchema } from "../../../../schema";
import Lottie from "lottie-react";
import animation from '../../../../ressources/lotti/animation_ll6y0o3v.json'
const LivingroomCleaningForm=React.forwardRef<any, {}>((props, ref) => {

  const [service, setService] = useAtom(serviceCardAtom);

  const toast = useToast();
  const deactivateForm = (e: any) => {
    e.preventDefault();
  };
  const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: {
      size:1,
      floor:1,
      toilet:"1",
      kitchen:"1",
      cleaningSupplyAvailibility:"Ja",
      interval:"Nein",
      date:"",
      time:"",
      time2:""
    },
    validationSchema: roomCleaningSchema,
    onSubmit: deactivateForm,
  });
  const addToCardHandler = () => {
    addToServices();
  };

  React.useImperativeHandle(ref, () => ({
    addToCardHandler,
  }));

  const addToServices=()=>{
    setService([...service, {id:uuid(),type:'Haus',
service:'Wohnraumreinigung',
specification1:'m2: '+values.size,
specification2:'Stockwerke: ' +values.floor,
specification3:'Anzahl Badezimmer: '+values. toilet,
specification4:'Anzahl Küchen: '+ values.kitchen,
specification5:'Intervall: ' + values.interval,
specification6:'Reinigungsmaterial zur Verfügung stellen: ' +values.cleaningSupplyAvailibility,
date: formatDate(values.date),
time:values.time + ' bis ' +values.time2
}])

toast({
  title: 'Wohnraumreinigung',
  description: "Wurde im Warenkorb hinzugefügt",
  status: 'success',
  duration: 3000,
  isClosable: true,
  position:'top-right'
})
  }
  return(

    <section className="bg-white dark:bg-gray-900 w-[100%]">
    <div className=" px-4 py-8 w-[100%] ">
      <form onSubmit={deactivateForm}>
        <div className="grid gap-4 mb-4 sm:grid-cols-2 sm:gap-6 sm:mb-5  w-[100%]">
          <div>
            <label
              htmlFor="size"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              m2
            </label>
            <input
            type="number"
          min="1"
              name="size"
              id="size"
              value={values.size}
              onChange={handleChange}
              className={
                errors.size
                  ? "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-600 focus:border-red-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600    dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500   dark:focus:border-red-500"
                  : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600  focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500  dark:focus:border-primary-500"
              }
            />
            {errors.size && (
              <p className="text-red-500 text-xs mt-1">
                 der Quadratmeter darf nicht negativ sein!
              </p>
            )}
          </div>
          <div>
            <label
              htmlFor="floor"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Stockwerke
            </label>
            <input
             min="1"
              type="number"
              name="floor"
              id="floor"
              value={values.floor}
              onChange={handleChange}
              className={
                errors.floor
                  ? "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-600 focus:border-red-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600    dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500   dark:focus:border-red-500"
                  : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600  focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500  dark:focus:border-primary-500"
              }
            />
            {errors.floor && (
              <p className="text-red-500 text-xs mt-1">
                 Die Anzahl der Stockwerke darf nicht negativ sein!
              </p>
            )}
          </div>
          <div>
            <label
              htmlFor="toilet"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Anzahl Badezimmer
            </label>
            <input
             min="1"
              type="number"
              name="toilet"
              id="toilet"
              value={values.toilet}
              onChange={handleChange}
              className={
                errors.toilet
                  ? "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-600 focus:border-red-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600    dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500   dark:focus:border-red-500"
                  : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600  focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500  dark:focus:border-primary-500"
              }
            />
            {errors.toilet && (
              <p className="text-red-500 text-xs mt-1">
                 Die Anzahl des Badezimmers darf nicht negativ sein!
              </p>
            )}
          </div>
          <div>
            <label
              htmlFor="kitchen"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Anzahl Küchen
            </label>
            <input
             min="1"
              type="number"
              name="kitchen"
              id="kitchen"
              value={values.kitchen}
              onChange={handleChange}
              className={
                errors.kitchen
                  ? "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-600 focus:border-red-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600    dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500   dark:focus:border-red-500"
                  : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600  focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500  dark:focus:border-primary-500"
              }
            />
            {errors.kitchen && (
              <p className="text-red-500 text-xs mt-1">
                 Die Anzahl der Küchen darf nicht negativ sein!
              </p>
            )}
            
    
          </div>
       
          <div  className="sm:col-span-2">
            <label
              htmlFor="sides"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Reinigungsmaterial zur Verfügung stellen?
            </label>
            <select
              id="sides"
              value={values.cleaningSupplyAvailibility}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
            >
              <option value="Ja" defaultValue="Ja">
                Ja
              </option>
              <option value="Nein">Nein</option>
            </select>
          </div>
          <div className="sm:col-span-2">
          <label
              htmlFor="date"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
             Wunschtermin
            </label>
            <input
              type="date"
              name="date"
              id="date"
              value={values.date}
              onChange={handleChange}
              className={
                errors.date
                  ? "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-600 focus:border-red-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                  : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              }
            />
            {errors.date && (
              <p className="text-red-500 text-xs mt-1">
                Bitte wählen sie einen Wunschtermin
              </p>
            )}
          </div>
          <div  >
            <label
              htmlFor="time"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
             Zeit von
            </label>
            <select
              id="time"
              value={values.time}
              onChange={handleChange}
              className={
                errors.time
                  ? "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                  : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              }            >
           <option defaultValue="6:00">6:00</option>
              <option value="6:30">6:30</option>
              <option value="7:00">7:00</option>
              <option value="7:30">7:30</option>
              <option value="8:00">8:00</option>
              <option value="8:30">8:30</option>
              <option value="9:00">9:00</option>
              <option value="9:30">9:30</option>
              <option value="10:00">10:00</option>
              <option value="10:30">10:30</option>
              <option value="11:00">11:00</option>
              <option value="11:30">11:30</option>
              <option value="12:00">12:00</option>
              <option value="12:30">12:30</option>
              <option value="13:00">13:00</option>
              <option value="13:30">13:30</option>
              <option value="14:00">14:00</option>
              <option value="14:30">14:30</option>
              <option value="15:00">15:00</option>
              <option value="15:30">15:30</option>
              <option value="16:00">16:00</option>
              <option value="16:30">16:30</option>
              <option value="17:00">17:00</option>
              <option value="17:30">17:30</option>
              <option value="18:00">18:00</option>
              <option value="18:30">18:30</option>
              <option value="19:00">19:00</option>
              <option value="19:30">19:30</option>
              <option value="20:00">20:00</option>
              <option value="20:30">20:30</option>
              <option value="21:00">21:00</option>
            </select>
            {errors.time && (
                <p className="text-red-500 text-xs mt-1">
                  Bitte wählen sie ein Wunschzeitfenster
                </p>
              )}
          </div>
          <div  >
            <label
              htmlFor="time2"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
             bis
            </label>
            <select
              id="time2"
              value={values.time2}
              onChange={handleChange}
              className={
                errors.time2
                  ? "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                  : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              }            >
                <option defaultValue="6:00">6:00</option>
              <option value="6:30">6:30</option>
              <option value="7:00">7:00</option>
              <option value="7:30">7:30</option>
              <option value="8:00">8:00</option>
              <option value="8:30">8:30</option>
              <option value="9:00">9:00</option>
              <option value="9:30">9:30</option>
              <option value="10:00">10:00</option>
              <option value="10:30">10:30</option>
              <option value="11:00">11:00</option>
              <option value="11:30">11:30</option>
              <option value="12:00">12:00</option>
              <option value="12:30">12:30</option>
              <option value="13:00">13:00</option>
              <option value="13:30">13:30</option>
              <option value="14:00">14:00</option>
              <option value="14:30">14:30</option>
              <option value="15:00">15:00</option>
              <option value="15:30">15:30</option>
              <option value="16:00">16:00</option>
              <option value="16:30">16:30</option>
              <option value="17:00">17:00</option>
              <option value="17:30">17:30</option>
              <option value="18:00">18:00</option>
              <option value="18:30">18:30</option>
              <option value="19:00">19:00</option>
              <option value="19:30">19:30</option>
              <option value="20:00">20:00</option>
              <option value="20:30">20:30</option>
              <option value="21:00">21:00</option>
            </select>
            {errors.time2 && (
              <p className="text-red-500 text-xs mt-1">
                Bitte wählen sie ein Wunschzeitfenster
              </p>
            )}
          </div>
          <div  className="sm:col-span-2">
            <label
              htmlFor="interval"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Intervall
            </label>
            <select
              id="interval"
              value={values.interval}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
            >
              
              <option defaultValue="Nein">Nein</option>
              <option value="Wöchenlich">Wöchenlich</option>
              <option value="Zwei mal Wöchenlich">Zwei mal Wöchenlich</option>
              <option value="Alle zwei Wochen">Alle zwei Wochen</option>                     
              <option value="Monatlich">Monatlich</option>
            </select>
          </div>
        </div>
      </form>
    </div>
  </section> 
  )
})

const LivingroomCleaningRequest = (props:any) => {
  const [open, setOpen] = React.useState(false);

    const toast = useToast()
 



  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  


  const handleClickOpen = () => {
    setOpen(true);
  };

  const addServiceHanler = () => {
    props.onModalHandler()
  
    handleTriggerChildMethod();
              
     
    setOpen(false);
  };
  const handleClose = () => {
   
    props.onModalHandler()
  };
  const handleDialogClick = (event:any) => {
 
    event.stopPropagation();
  };
  const childRef = React.useRef<any | null>(null);

  const handleTriggerChildMethod = () => {
    childRef.current?.addToCardHandler();
  };
  return (
    <div>
      <button
        onClick={handleClickOpen}
        className="uppercase  bottom-3 shadow-lg shadow-[#b1c2a3] bg-gradient-to-r from-[#C0CDB5] via-[#a4b496] to-[#a4b496] hover:bg-gradient-to-br  active:bg-[#92a583]  md:align-middle   bg-[#C0CDB5] w-[200px] rounded-md font-medium my-6 md:mx-auto ml-10 py-3 text-black"
      >
        In den Warenkorb
      </button>

      <Dialog
        fullScreen={fullScreen}
        open={props.openDialog}
      
        aria-labelledby="responsive-dialog-title"
        fullWidth={true}
        onClick={handleDialogClick}
      >
       
        <DialogTitle id="responsive-dialog-title" sx={{backgroundColor:'#f1f2f6'}}>
        <div className="w-full bg-[#f1f2f6]">
        <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">
        Wohnraumreinigung
          </h2>
        </div>
         
          <div className="w-full flex justify-center">
          <div className="w-[200px] h-[200px]">
              <Lottie animationData={animation} loop={true} />
            </div>
          </div>
         
        </DialogTitle>
        <DialogContent>
         
      <LivingroomCleaningForm  ref={childRef}/>
         
        </DialogContent>
        <DialogActions>
          <Button color="error" autoFocus onClick={handleClose}>
            Beenden
          </Button>
          <Button onClick={addServiceHanler} autoFocus>
            Hinzufügen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LivingroomCleaningRequest;
