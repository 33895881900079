import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCcEFARKW_AiIU-C6Mfkm8gHA4KXaFZF2U",
  authDomain: "servicecleaning-8c9ed.firebaseapp.com",
  projectId: "servicecleaning-8c9ed",
  storageBucket: "servicecleaning-8c9ed.appspot.com",
  messagingSenderId: "672265756022",
  appId: "1:672265756022:web:6c94f6c7a2914df1602e57",
  measurementId: "G-TGKF15KSZ0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const db = getFirestore(app);
export const auth = getAuth(app);