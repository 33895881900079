export function today ():string{
    const currentDate: Date = new Date();
const todayDate: string = currentDate.toISOString().slice(0, 10);
return todayDate
  }
  export function timeNow ():string{
    const currentDate: Date = new Date();
    const currentTime: string = `${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}`;
return currentTime
  }
  export function timeNowPlusTwo ():string{
    const currentDate: Date = new Date();
    currentDate.setHours(currentDate.getHours() + 2);

const currentTime: string = `${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}`;
return currentTime
  }
  export const formatDate = (dateString:string):string => {
    const dateObj = new Date(dateString);
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = dateObj.getFullYear();
    return `${day}.${month}.${year}`;
  };