import React from "react";
import hand from '../ressources/images/helpinghandlogo.png'
import house from '../ressources/images/houseservicelogo.png'
import gardening from '../ressources/images/gardeninglogo.png'
import { Link } from "react-router-dom";

const Services = (props:any) => {
  return (
    <div className= {`w-full py-[3rem] px-4 ${props.bg !==undefined ? props.bg : "bg-white"}`} >
    <div className="max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8 my-[150px]">
      <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <Link to={"/housecleaning"}>
          <img
            className="rounded-lg hover:opacity-80 hover:bg-[#e8e0da] hover:scale-105 duration-300"
            src={house}
            alt=""
          />
        </Link>
       
      </div>
      <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
      <Link to={"/gardeningrequest"}>
          <img
            className="rounded-lg hover:opacity-80 hover:bg-[#e8e0da] hover:scale-105 duration-300"
            src={gardening}
            alt=""
          />
        </Link>
      
      </div>
      <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
      <Link to={"/helpinghands"}>
          <img
            className="rounded-lg hover:opacity-80 hover:bg-[#e8e0da] hover:scale-105 duration-300"
            src={hand}
            alt=""
          />
        </Link>
 
      </div>
    </div>
    </div>
  );
};

export default Services;
