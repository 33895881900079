import React from 'react'
import Lottie from "lottie-react";
import { useFormik } from "formik";
import animation from '../../../ressources//lotti/animation_cleaner.json'
import { jobApplicationInfoSchema } from "../../../schema";
import { useToast } from '@chakra-ui/react';
import {
  addDoc,
  collection,

} from "firebase/firestore";
import { db } from "../../../firebase.config";
import JobApplicationFinishedDialog from '../components/Dialogs/JobApplicationFinishedDialog';
const JobApplication = () => {
  const errorToast = useToast();
  const [open, setOpen] = React.useState(false);
  function modalHandler(e: any) {
    setOpen(!open);
  }
  function cloneModal() {
    setOpen(false);
  }
  const sendHandler=(e:any)=>{
   e.preventDefault(); 
   if(values.firstName==''||values.lastName==''|| values.hours==''||values.phonenumber==''){
 
    errorToast({
        title: "Fehler",
        description: "Bitte füllen Sie alle Pflichtfelder aus, bevor Sie Ihre Informationen absenden.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
  return;
  }

   addTomailDatabase().then(()=>{
    setOpen(!open)
}).catch((err)=>{
errorToast({
  title: "Fehler",
  description: err.message,

  status: "error",
  duration: 9000,
  isClosable: true,
  position: "top-right",
});
})



  }

  const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "", 
      phonenumber: "", 
      email: "",
      hours:""
    },
    validationSchema: jobApplicationInfoSchema,
    onSubmit: sendHandler,
  });
  const mailCollectionRef = collection(db, "mail");
  const addTomailDatabase = async () => {
    await addDoc(mailCollectionRef, {
      to:
        values.email === ""
          ? ["service@diehausmaennerinnen.at"]
          : [values?.email, "service@diehausmaennerinnen.at"],
      message: {
        subject: "Mitarbeiter",
        body: "",
        html: emailBody,
      },
    })
      .then(() => {
        
      })
      .catch((err) => {
      
      });
  };

const emailBody = `
<html>

<head>
  <style>
    table {
      border-collapse: collapse;
      width: 100%;
    }
    th, td {
      border: 1px solid black;
      padding: 8px;
    }
    a {
      color: blue;
      text-decoration: underline;
    }
    .contact-info {
     
      
    }
  </style>
</head>
  <body>
  <h5>Sehr geehrte/r ${values.firstName} ${values.lastName},</h5>
  
  <p>Mit freundlichen Grüßen</p>
  <p>Das Hausmännerinnen-Team</p>
  <p>Die Hausmännerinnen</p>
  <div class="contact-info">
  <p>Tel: <a href="tel:+436606200600">0660 / 6 200 600</a></p>
  <p>Email: <a href="mailto:service@diehausmaennerinnen.at">service@diehausmaennerinnen.at</a></p>
  <p>Website: <a href="https://xn--diehausmnnerinnen-xqb.at/">diehausmännerinnen.at</a></p>
</div>
<hr/>

<h1>Anfrage</h1>
  <ul>
  <li> Vorname: ${values.firstName}</li>
  <li> Nachname:  ${values.lastName}</li>
 

  <li> Postleitzahl: 2384</li>
  <li> Ort: Breitenfurt bei Wien</li>
  <li> Handynummer: ${values.phonenumber}</li>

  <li> Email adresse: ${values?.email}</li>
  </ul>
   

  
   
  </body>
</html>
`;
  return (

    <section
      className="bg-[#f7f7f7] min-h-screen flex items-center w-[100%] h-[70%]
justify-center"
    >

      <div
        className="bg-[#f7f8f7]  rounded-2xl shadow-lg
         p-5 w-[80%] min-h-[600px] "
      >
        <div className='w-full bg-[#f7f8f7]  py-6 px-4 '>
          <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
            <Lottie animationData={animation} className='md:w-[500px] mx-auto my-4' alt="/cleaningman" />
            <div className='flex flex-col justify-center '>
              <p className='text-[#91a084] font-bold text-md'>
                Du bist StudentIn, du hast Zeit und arbeitest gerne mit Menschen?<br/>
                Du bist zu Hause aber möchtest in einem netten Team arbeiten?<br/>
                Du bist Pensionist und du möchtest Dir tolle Urlaube finanzieren?
              </p>
              <h1 className='md:text-3xl sm:text-2xl text-xl font-[1000] md:py-2 my-5'>
                Dann suchen wir Dich
                </h1>
              <p>Werde Mitarbeiter in unserem ServiceTeam. Wir suchen Menschen, die Freude daran haben,sauber zu machen.</p>
              <p className='mt-5'>Wir sind ein Unternehmen in Breitenfurt/Wien und suchen engagierte Mitarbeiter für unser familiäres Team.</p>
              <p className='mt-5'><span className='font-bold'>Wir bieten: </span> tolles Abeitsklima,freundliche Kollegen,attraktive Arbeitszeiten und gute Bezahlung.</p>
              <p className='mt-5'><span className='font-bold'>Wir suchen: </span> Mitarbeiter, bei denen Qualität groß geschrieben wird und die Wert auf Pünkliche und zuverlässige Arbeiten legen.</p>
              <p className='mt-8'>Helfen Sie uns, unseren Kunden ein Lächeln ins Gesicht zu zaubern!</p>

            </div>

          </div>

        </div>
        <div className="md:h-[50vh] h-auto m-10 bg-[#f7f7f7]">
          <form onSubmit={sendHandler}>
            <div className="grid gap-6  md:grid-cols-2">
              <div>

                <label
                  htmlFor="firstName"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Vorname* (Pflichtfelder)
                </label>
                <input
                  type="text"
                  id="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                    className={
                      errors.firstName
                        ? "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 "
                        : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5 "
                    }
                  placeholder="Max"
                />
                {errors.firstName && (
                  <p className="text-red-500 text-xs mt-1">
                    Bitte füllen Sie alle erforderlichen Felder aus
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="lastName"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Nachname* (Pflichtfelder)
                </label>
                <input
                  type="text"
                  value={values.lastName}
                  onChange={handleChange}
                  id="lastName"
                  className={
                    errors.lastName
                      ? "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 "
                      : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5 "
                  }
                  placeholder="Mustermann"
                />
                {errors.lastName && (
                  <p className="text-red-500 text-xs mt-1">
                    Bitte füllen Sie alle erforderlichen Felder aus
                  </p>
                )}
              </div>



              <div>
                <label
                  htmlFor="phonenumber"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Handynummer* (Pflichtfelder)
                </label>
                <input
                  type="tel"
                  id="phonenumber"

                    value={values.phonenumber as string}
                    onChange={handleChange}
                    className={
                      errors.phonenumber
                        ? "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full  p-2.5 "
                        : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5 "
                    }
                  placeholder="0644123456"
                />
                {errors.phonenumber && (
                  <p className="text-red-500 text-xs mt-1">
                    Bitte füllen Sie alle erforderlichen Felder aus
                  </p>
                )}
              </div>
              <div className="mb-6">
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Email adresse
                </label>
                <input
                  type="email"
                  id="email"

                  value={values.email as string}
                  onChange={handleChange}
                  className=

                  "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5 "

                  placeholder="max@mustermann.com"
                />
              </div>
            </div>
            <h3 className="mb-5 text-lg font-medium text-gray-900 dark:text-white">Wieviel Zeit kann ich zur Verfügung stellen*:  (Pflichtfelder)</h3>

            <ul className="grid w-full gap-6 md:grid-cols-4">
              <li>

              <input type="radio" 
             id="hours10" name="hours"  
          
             value="hours10"
                  onChange={handleChange} 
                    className="hidden peer" />
                <label htmlFor="hours10" className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                  <div className="block">
                    <div className="w-full text-lg font-semibold">10 Stunden </div>
                    <div className="w-full"> Pro Woche</div>
                  </div>

                </label>
              </li>
              <li>
             

              <input type="radio" id="hours20" name="hours" 
            
               value="hours20" 
                   onChange={handleChange} 
                    className="hidden peer" />
                <label htmlFor="hours20" className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                  <div className="block">
                    <div className="w-full text-lg font-semibold">20 Stunden</div>
                    <div className="w-full"> Pro Woche</div>
                  </div>

                </label>
              </li>
              <li>
              <input type="radio" id="hours30" name="hours"   value="hours30"
                    onChange={handleChange} className="hidden peer" />
                <label htmlFor="hours30" className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                  <div className="block">
                    <div className="w-full text-lg font-semibold">30 Stunden</div>
                    <div className="w-full"> Pro Woche</div>
                  </div>

                </label>
              </li>
              <li>
                <input type="radio" id="hours40" name="hours"   value="hours40"
                    onChange={handleChange} className="hidden peer" />
                <label htmlFor="hours40" className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                  <div className="block">
                    <div className="w-full text-lg font-semibold">40 Stunden</div>
                    <div className="w-full"> Pro Woche</div>
                  </div>

                </label>
              </li>
            </ul>
            <div className="flex flex-row justify-start">
              <div>
                <button onClick={sendHandler}
                 className='uppercase text-white bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 md:w-[300px] w-[200px] rounded-md font-medium md:mt-[80px] my-6 mx-auto py-3 '>
                  Anfrage abschicken
                </button>
              </div>
             
            </div>
          </form>
        </div>
<JobApplicationFinishedDialog   openDialog={open} onModalHandler={cloneModal} />
      </div>

    </section>
  )
}

export default JobApplication