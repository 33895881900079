import React from 'react'
import { useAtom } from 'jotai'
import { authUser } from '../stores/authAtom'
import { Navigate,useLocation,Outlet } from 'react-router-dom'
import Navbar from '../components/global/Navbar'
import Footer from '../components/global/Footer'
const RequireAuthLayout = () => {
    const [user,userUser]=useAtom(authUser);
    const location=useLocation()
    
  return (
<>
<Navbar/>
  {user!==undefined ? <Outlet/> : <Navigate to="/signinmethods" state={{from:location}} replace/>}
  <Footer/>
  </>

  )
}

export default RequireAuthLayout