import React from 'react'
import { CircularProgress, TextField } from "@mui/material";
import cleaningImg from "../../../ressources/images/cleaningservicet.png";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import {auth } from "../../../firebase.config";
import { useFormik } from "formik";
import { signupSchema } from "../../../schema";
import { useToast } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { useAtom } from 'jotai';
import { authUser } from '../../../stores/authAtom';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { FcAddressBook,FcLock } from "react-icons/fc";
const SigninWithEmail = () => {
    const toast = useToast();
    const auth = getAuth();
    const navigate = useNavigate();
    const [signsinWithEmailAndPassword, user, loading, error] =
    useSignInWithEmailAndPassword(auth);
  const[userAtom,setUserAtom]=useAtom(authUser);
    const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
      initialValues: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        passwordConfirmation: "",
      },
      validationSchema: signupSchema,
      onSubmit: (values) => {
        alert(JSON.stringify(values, null, 2));
      },
    });
  
    const SignInHandler = async (e:any) => {
 e.preventDefault();
 signInWithEmailAndPassword(auth, values.email, values.password)
  .then((userCredential) => {
    // Signed in 
    const user = userCredential.user;
    setUserAtom(user)
    navigate('/dashboard');
    // ...
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    toast({
      title: "Fehler",
      description: errorCode,
      status: "error",
      duration: 9000,
      isClosable: true,
      position: "top-right",
    });
  });

    };
    
    const errorToastHandler = () => {
      toast({
        title: "Fehler",
        description: error?.message,
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
    };
  
    const successToastHandler = () => {
      toast({
        title: "Willkomen",
        description: user?.user.email,
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
    };
  
    return (
      <>
        <section
          className="bg-[#f7f7f7] min-h-screen flex items-center
  justify-center"
        >
          <div
            className="bg-[#f7f8f7] flex rounded-2xl shadow-lg
          max-w-3xl p-5"
          >
            <div className="sm:w-1/2 px-16 ">
              <h2 className="font-bold text-2xl text-[#C0CDB5] mb-10">
              Anmelden
              </h2>
  
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Ihr Email
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                    <FcAddressBook size={25}/>
                  </div>
                  <div></div>
                  <input
                    type="email"
                    id="email"
                    value={values.email}
                    onChange={handleChange}
                    className={
                      errors.email
                        ? "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full pl-10 p-2.5 "
                        : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 "
                    }
                    placeholder="max@mustermann.at"
                  />
                </div>
  
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium mt-3 text-gray-900 dark:text-white"
                >
                  Password
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                 
                 <FcLock size={25}/>
                  </div>
                  <input
                    type="password"
                    id="password"
                    value={values.password}
                    onChange={handleChange}
                    className={
                      errors.password
                        ? "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full pl-10 p-2.5 "
                        : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 "
                    }
                    placeholder="******"
                  />
                </div>
  
               
  
                <button
                  className="uppercase shadow-lg mt-10 bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br
                  focus:ring-4 focus:outline-none focus:ring-cyan-300 
                 rounded-md font-medium my-3 mx-auto py-2 w-[230px] text-white"
                  onClick={SignInHandler}
                >
                  Anmelden
                </button>
                {loading && <CircularProgress color="success" />}
                <div className="flex flex-row text-blue-400 text-center text-[10px]">
                <p>
                  <Link to={'/signupeithemailandpassword'}>
                    Kein Konto/
                  </Link>
                </p>
                {/* <p>Kennwort vergessen</p> */}
              </div>
  
              <div
                className="mt-10 grid grid-cols-3 items-center
               text-gray-400"
              ></div>
            </div>
            <div className="w-1/2">
              <img
                className="sm:block hidden rounded-2xl"
                src={cleaningImg}
                alt="#"
              />
            </div>
          </div>
        </section>
      </>
    );
 
}

export default SigninWithEmail